import Token6 from '@/assets/images/collection/stackslash/stackslash-6.jpg'
import Token20 from '@/assets/images/collection/stackslash/stackslash-20.jpg'
import Token24 from '@/assets/images/collection/stackslash/stackslash-24.jpg'
import Token35 from '@/assets/images/collection/stackslash/stackslash-35.jpg'
import Token38 from '@/assets/images/collection/stackslash/stackslash-38.jpg'
import Token46 from '@/assets/images/collection/stackslash/stackslash-46.jpg'
import Token62 from '@/assets/images/collection/stackslash/stackslash-62.jpg'
import Token69 from '@/assets/images/collection/stackslash/stackslash-69.jpg'
import Token74 from '@/assets/images/collection/stackslash/stackslash-74.jpg'
import Token76 from '@/assets/images/collection/stackslash/stackslash-76.jpg'
import Token93 from '@/assets/images/collection/stackslash/stackslash-93.jpg'
import Token103 from '@/assets/images/collection/stackslash/stackslash-103.jpg'
import Token6Mobile from '@/assets/images/collection/stackslash/stackslash-6-mobile.jpg'
import Token20Mobile from '@/assets/images/collection/stackslash/stackslash-20-mobile.jpg'
import Token24Mobile from '@/assets/images/collection/stackslash/stackslash-24-mobile.jpg'
import Token35Mobile from '@/assets/images/collection/stackslash/stackslash-35-mobile.jpg'
import Token38Mobile from '@/assets/images/collection/stackslash/stackslash-38-mobile.jpg'
import Token46Mobile from '@/assets/images/collection/stackslash/stackslash-46-mobile.jpg'
import Token62Mobile from '@/assets/images/collection/stackslash/stackslash-62-mobile.jpg'
import Token69Mobile from '@/assets/images/collection/stackslash/stackslash-69-mobile.jpg'
import Token74Mobile from '@/assets/images/collection/stackslash/stackslash-74-mobile.jpg'
import Token76Mobile from '@/assets/images/collection/stackslash/stackslash-76-mobile.jpg'
import Token93Mobile from '@/assets/images/collection/stackslash/stackslash-93-mobile.jpg'
import Token103Mobile from '@/assets/images/collection/stackslash/stackslash-103-mobile.jpg'

import ProcessOne from '@/assets/images/collection/stackslash/stackslash-process-1.jpg'
import ProcessTwo from '@/assets/images/collection/stackslash/stackslash-process-2.jpg'
import ProcessThree from '@/assets/images/collection/stackslash/stackslash-process-3.jpg'
import ProcessFour from '@/assets/images/collection/stackslash/stackslash-process-4.jpg'
import ProcessFive from '@/assets/images/collection/stackslash/stackslash-process-5.jpg'
import ProcessSix from '@/assets/images/collection/stackslash/stackslash-process-6.jpg'
import ProcessSeven from '@/assets/images/collection/stackslash/stackslash-process-7.jpg'
import ProcessEight from '@/assets/images/collection/stackslash/stackslash-process-8.jpg'
import ProcessOneMobile from '@/assets/images/collection/stackslash/stackslash-process-1-mobile.jpg'
import ProcessTwoMobile from '@/assets/images/collection/stackslash/stackslash-process-2-mobile.jpg'
import ProcessThreeMobile from '@/assets/images/collection/stackslash/stackslash-process-3-mobile.jpg'
import ProcessFourMobile from '@/assets/images/collection/stackslash/stackslash-process-4-mobile.jpg'
import ProcessFiveMobile from '@/assets/images/collection/stackslash/stackslash-process-5-mobile.jpg'
import ProcessSixMobile from '@/assets/images/collection/stackslash/stackslash-process-6-mobile.jpg'
import ProcessSevenMobile from '@/assets/images/collection/stackslash/stackslash-process-7-mobile.jpg'
import ProcessEightMobile from '@/assets/images/collection/stackslash/stackslash-process-8-mobile.jpg'

import InspectLarge from '@/assets/images/collection/stackslash/stackslash-inspect-large.jpg'
import InspectMedium from '@/assets/images/collection/stackslash/stackslash-inspect-medium.jpg'
import InspectSmall from '@/assets/images/collection/stackslash/stackslash-inspect-small.jpg'
import InspectPortrait from '@/assets/images/collection/stackslash/stackslash-inspect-portrait.jpg'

export const getPreviewImages = () => [
  { src: Token35, srcMobile: Token35Mobile },
  { src: Token24, srcMobile: Token24Mobile },
  { src: Token6, srcMobile: Token6Mobile },
  { src: Token74, srcMobile: Token74Mobile },
]

export const getShowcaseImages = () => [
  { src: Token24, srcMobile: Token24Mobile },
  { src: Token6, srcMobile: Token6Mobile },
  { src: Token74, srcMobile: Token74Mobile },
  { src: Token103, srcMobile: Token103Mobile },
  { src: Token38, srcMobile: Token38Mobile },
  { src: Token46, srcMobile: Token46Mobile },
  { src: Token76, srcMobile: Token76Mobile },
  { src: Token20, srcMobile: Token20Mobile },
  { src: Token69, srcMobile: Token69Mobile },
  { src: Token62, srcMobile: Token62Mobile },
  { src: Token93, srcMobile: Token93Mobile },
]

export const getProcessImages = () => [
  { src: ProcessOne, srcMobile: ProcessOneMobile },
  { src: ProcessTwo, srcMobile: ProcessTwoMobile },
  { src: ProcessThree, srcMobile: ProcessThreeMobile },
  { src: ProcessFour, srcMobile: ProcessFourMobile },
  { src: ProcessFive, srcMobile: ProcessFiveMobile },
  { src: ProcessSix, srcMobile: ProcessSixMobile },
  { src: ProcessSeven, srcMobile: ProcessSevenMobile },
  { src: ProcessEight, srcMobile: ProcessEightMobile },
]

export const getInspectImage = () => ({
  srcLarge: InspectLarge,
  srcMedium: InspectMedium,
  srcSmall: InspectSmall,
  srcPortrait: InspectPortrait,
})

/*
  Detect versions of Microsoft Internet Explorer/Edge
*/

export function microsoft() {

  if ( typeof window === 'undefined' ) return false

  const ua = window.navigator.userAgent
  const msie = ua.indexOf('MSIE ')
  const trident = ua.indexOf('Trident/')
  const edge = ua.indexOf('Edge/')
  const edgeChromium = ua.indexOf('Edg/')

  if ( msie > 0 ) return '10'
  else if ( trident > 0 ) return '11'
  else if ( edge > 0 ) return 'Edge'
  else if ( edgeChromium > 0 ) return 'EdgeChromium'
  return false
}


/*
  Detect Firefox
*/

export function firefox() {

  if ( typeof window === 'undefined' ) return false

  const ua = window.navigator.userAgent

  return /firefox/i.test( ua )
}


/*
  Detect Safari
*/

export function safari() {

  if ( typeof window === 'undefined' ) return false

  const ua = window.navigator.userAgent

  return /^((?!chrome|android).)*safari/i.test( ua )
}


/*
  Detect iOS
*/

export function iOS() {

  if ( typeof window === 'undefined' ) return false

  const ua = window.navigator.userAgent

  return /iPad|iPhone|iPod/.test( ua ) && !window.MSStream
}

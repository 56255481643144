import Vue from 'vue'
import Router from 'vue-router'

import ROUTE_NAMES from '@/data/routes'
import collection from '@/data/collection'

Vue.use( Router )

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: ROUTE_NAMES.HOME,
      component: () => import( './views/Home.vue' )
    },
    {
      path: '/piece/:slug',
      name: ROUTE_NAMES.PIECE,
      props: true,
      component: () => import( './views/Piece.vue' ),
      beforeEnter: (to, from, next) => {

        const pieceNotFound = collection.filter( piece => ( piece.slug === to.params.slug ) ).length === 0

        // Redirect to 404 if no piece matching this slug exists in the collection
        if ( pieceNotFound ) {
          next( '/404' )
        }
        else {
          next()
        }
      }
    },
    // {
    //   path: '/study',
    //   name: ROUTE_NAMES.STUDY,
    //   component: () => import( ./views/Study.vue' )
    // },
    {
      path: '/about',
      name: ROUTE_NAMES.ABOUT,
      component: () => import( './views/About.vue' )
    },
    {
      path: '/legal',
      name: ROUTE_NAMES.LEGAL,
      component: () => import( './views/Legal.vue' )
    },
    {
      path: '*',
      name: ROUTE_NAMES.NOTFOUND,
      component: () => import( './views/NotFound.vue' ),
    }
  ]
})

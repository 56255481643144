import One from "@/assets/images/collection/ident/Ident-1.png";
import Two from "@/assets/images/collection/ident/Ident-2.png";
import Three from "@/assets/images/collection/ident/Ident-3.png";
import Four from "@/assets/images/collection/ident/Ident-4.png";
import OneMobile from "@/assets/images/collection/ident/Ident-1-mobile.png";
import TwoMobile from "@/assets/images/collection/ident/Ident-2-mobile.png";
import ThreeMobile from "@/assets/images/collection/ident/Ident-3-mobile.png";
import FourMobile from "@/assets/images/collection/ident/Ident-4-mobile.png";

import ProcessOne from "@/assets/images/collection/ident/Ident-Process-1.png";
import ProcessTwo from "@/assets/images/collection/ident/Ident-Process-2.png";
import ProcessThree from "@/assets/images/collection/ident/Ident-Process-3.png";
import ProcessFour from "@/assets/images/collection/ident/Ident-Process-4.png";
import ProcessFive from "@/assets/images/collection/ident/Ident-Process-5.png";
import ProcessSix from "@/assets/images/collection/ident/Ident-Process-6.png";
import ProcessSeven from "@/assets/images/collection/ident/Ident-Process-7.png";
import ProcessEight from "@/assets/images/collection/ident/Ident-Process-8.png";
import ProcessNine from "@/assets/images/collection/ident/Ident-Process-9.png";
import ProcessTen from "@/assets/images/collection/ident/Ident-Process-10.png";
import ProcessOneMobile from "@/assets/images/collection/ident/Ident-Process-1-mobile.png";
import ProcessTwoMobile from "@/assets/images/collection/ident/Ident-Process-2-mobile.png";
import ProcessThreeMobile from "@/assets/images/collection/ident/Ident-Process-3-mobile.png";
import ProcessFourMobile from "@/assets/images/collection/ident/Ident-Process-4-mobile.png";
import ProcessFiveMobile from "@/assets/images/collection/ident/Ident-Process-5-mobile.png";
import ProcessSixMobile from "@/assets/images/collection/ident/Ident-Process-6-mobile.png";
import ProcessSevenMobile from "@/assets/images/collection/ident/Ident-Process-7-mobile.png";
import ProcessEightMobile from "@/assets/images/collection/ident/Ident-Process-8-mobile.png";
import ProcessNineMobile from "@/assets/images/collection/ident/Ident-Process-9-mobile.png";
import ProcessTenMobile from "@/assets/images/collection/ident/Ident-Process-10-mobile.png";

export const getPreviewImages = () => [
  { src: One, srcMobile: OneMobile },
  { src: Two, srcMobile: TwoMobile },
  { src: Three, srcMobile: ThreeMobile },
  { src: Four, srcMobile: FourMobile }
];

export const getShowcaseImages = () => [
  { src: Two, srcMobile: TwoMobile },
  { src: Three, srcMobile: ThreeMobile },
  { src: Four, srcMobile: FourMobile }
];

export const getProcessImages = () => [
  { src: ProcessOne, srcMobile: ProcessOneMobile },
  { src: ProcessTwo, srcMobile: ProcessTwoMobile },
  { src: ProcessThree, srcMobile: ProcessThreeMobile },
  { src: ProcessFour, srcMobile: ProcessFourMobile },
  { src: ProcessFive, srcMobile: ProcessFiveMobile },
  { src: ProcessSix, srcMobile: ProcessSixMobile },
  { src: ProcessSeven, srcMobile: ProcessSevenMobile },
  { src: ProcessEight, srcMobile: ProcessEightMobile },
  { src: ProcessNine, srcMobile: ProcessNineMobile, aspect: 1920 / 1080, scale: 1.2 },
  { src: ProcessTen, srcMobile: ProcessTenMobile, aspect: 1920 / 1080, scale: 1.2 }
];

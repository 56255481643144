<template>
  <div class="Preloader">
    <transition-group tag="p" mode="out-in" class="phrases" name="phrase">
      <span
        class="phrase"
        v-for="(phrase, index) in PHRASES"
        :key="phrase + index"
        v-show="index === phrasePosition && isMounted && !isComplete"
      >
        <span>{{ phrase }}</span>
      </span>
    </transition-group>
  </div>
</template>

<script>
import { getPreloadList } from '@/data/collection'

import ImageLoader from '@/utils/image-loader'

export default {
  name: 'Preloader',
  props: {
    onLoaded: {
      type: Function,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      isMounted: false,
      isComplete: false,
      PHRASES: [
        // '',
        'TEN',
        'TWENTY',
        'THIRTY',
        'FORTY',
        'FIFTY',
        'THANKS',
        'FOR',
        'YOUR',
        'PATIENCE',
        '',
        // 'PATIENCE'
        // 'SIXTY',
        // 'SEVENTY',
        // 'EIGHTY',
        // 'NINETY',
        // 'ONE HUNDY!'
      ],
      loadProgress: 0,
    }
  },
  computed: {
    phrasePosition() {
      return Math.round(
        Math.pow(this.loadProgress, 0.8) * (this.PHRASES.length - 1)
      )
    },
    phrase() {
      return this.PHRASES[this.phrasePosition]
    },
  },
  created() {
    this.imageLoader = new ImageLoader()
    this.imageLoader.load(
      getPreloadList(this.$store.state.isMobile),
      () => this.onLoadComplete(),
      (progress) => this.onLoadProgress(progress)
    )
  },
  mounted() {
    this.isMounted = true
  },
  methods: {
    onLoadComplete() {
      // Animate out...
      this.isComplete = true
      // ...Then dismiss
      setTimeout(this.onLoaded, 400)
    },
    onLoadProgress(progress) {
      this.loadProgress = progress
    },
  },
}
</script>

<style scoped lang="scss">
@use 'sass:math';
@import '@/styles/core.scss';

.Preloader {
  position: fixed;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  .phrases {
    position: absolute;
    top: 50%;

    width: 80vw;
    height: 0;
    margin: 0 10vw;

    .phrase-enter-active {
      transition: transform 0.8s 0.1s $ease-out-quart, opacity 0s 0.1s;
    }

    .phrase-leave-active {
      transition: transform 0.1s $ease-in-quart, opacity 0s 0.1s;
    }

    .phrase-enter,
    .phrase-leave-to {
      opacity: 0;
      transform: translate3d(0, calc(-50% + 1vw), 0);
    }
  }

  .phrase {
    position: absolute;
    left: 0;
    top: 0;

    display: block;
    width: 100%;
    margin: 0;

    text-align: center;

    @include fontBlack();
    color: $color-violet;
    font-size: 9vw;
    line-height: 1;

    transform: translate3d(0, -50%, 0);

    span {
      display: block;
    }

    @for $i from 1 through 10 {
      $min-scale: 0.2;
      $max-scale: 0.85;
      $position: math.div($i, 9);
      $eased: $position * $position;

      &:nth-of-type(#{$i}) span {
        transform: scale($min-scale + ($max-scale - $min-scale) * $eased);
      }
    }
  }
}
</style>

<template>
  <div
    class="SmoothScroll"
    :class="{
      'is-native': isNative,
      'is-active': !isNative,
      'is-locked': isLocked
    }"
  >
    <div class="fixed">
      <div class="smooth" ref="smooth">
        <transition
          name="reset"
          v-on:after-leave="afterResetTransitionLeave"
          v-on:after-enter="afterResetTransitionEnter"
        >
          <div v-show="!isResetting" class="reset" ref="reset">
            <slot :scrollToTop="scrollToTop" :setScrollTop="setScrollTop" />
          </div>
        </transition>
      </div>
    </div>
    <span v-if="!isNative" class="scroll" :style="{ height: scrollHeight }" />
  </div>
</template>

<script>
import ticker from "@/managers/TickManager";
import viewport from "@/managers/ViewportManager";

import * as Detect from "@/utils/detect";

const NATIVE_WIDTH_THRESHOLD = 1024;
const NATIVE_SCROLLTOP_THRESHOLD = 2;

export default {
  name: "SmoothScroll",
  props: {},
  components: {},
  data() {
    return {
      isNative: true,
      isMicrosoft: Detect.microsoft(),
      isFirefox: Detect.firefox(),
      isIOS: Detect.iOS(),
      scrollHeight: "100vh",
      isResetting: false,
      hasReset: true,
      shouldLock: false
    };
  },
  computed: {
    isLocked() {
      return this.$store.state.isScrollLocked;
    },
    isReducedPlatform() {
      return (
        this.isIOS ||
        this.isFirefox ||
        (this.isMicrosoft && this.isMicrosoft !== "EdgeChromium")
      );
    }
  },
  mounted() {
    this.resizeId = viewport.on("resize", this.onResize);
    this.scrollId = viewport.on("scroll", this.onScroll);
    this.tickId = ticker.on(this.onTick);

    this.scrollPos = 0;

    this.updateHeight();

    this.bindKeyNavigation();
  },
  beforeDestroy() {
    viewport.off(this.resizeId);
    viewport.off(this.scrollId);
    ticker.off(this.tickId);

    this.unbindKeyNavigation();
  },
  methods: {
    resetScrollPosition() {
      window.scrollTo(0, 0);
      this.scrollPos = viewport.latest.height * -1;
    },
    updateHeight() {
      this.scrollHeight = `${this.$refs.smooth.offsetHeight}px`;
    },
    onResize({ width }) {
      const { scrollTop } = viewport.latest;

      if (this.isReducedPlatform || width <= NATIVE_WIDTH_THRESHOLD) {
        this.isNative = true;
      } else {
        this.isNative = false;
      }

      this.scrollPos = scrollTop; // Ensure calculations don't NaN when moving between native and smooth

      // Defer to next tick to allow mounted piece dimensions to update first
      this.$nextTick(() => this.updateHeight());
    },
    onScroll({ scrollTop }) {
      const { height } = viewport.latest;
      // Set global shallow scroll flag
      if (!this.isNative && scrollTop < height * 0.5 && this.hasReset) {
        this.$store.commit("setScrollShallow", true);
      } else {
        this.$store.commit("setScrollShallow", false);
      }
    },
    onTick({ delta = 0 } = {}) {
      // Don't run smooth logic for native implementation
      if (this.isNative) return;

      const { scrollTop } = viewport.latest;

      const fpsDelta = delta / (1000 / 60);

      this.scrollPos += (scrollTop - this.scrollPos) * 0.12 * fpsDelta;

      if (Math.abs(scrollTop - this.scrollPos) < 0.05) {
        this.scrollPos = scrollTop;
        this.$store.commit("setScrolling", false);
      } else {
        this.$store.commit("setScrolling", true);
      }

      // Apply position
      this.$refs.smooth.style.mozTransform = `translate3d( 0, ${-this
        .scrollPos}px, 0 )`;
      this.$refs.smooth.style.webkitTransform = `translate3d( 0, ${-this
        .scrollPos}px, 0 )`;
      this.$refs.smooth.style.transform = `translate3d( 0, ${-this
        .scrollPos}px, 0 )`;
    },
    scrollToTop(immediate = false, lock = false) {
      const { scrollTop } = viewport.latest;

      if (immediate) {
        this.resetScrollPosition();
        return;
      }

      // Simple
      if (
        this.$store.state.isScrollShallow ||
        (this.isNative && scrollTop <= NATIVE_SCROLLTOP_THRESHOLD)
      ) {
        window.scrollTo(0, 0);
      }
      // Animated
      else {
        this.isResetting = true;
        this.hasReset = false;
      }

      if (lock) {
        this.shouldLock = true;
      }
    },
    setScrollTop(position) {
      this.scrollPos = position;
      window.scrollTo(0, position);
      this.onTick();
    },
    afterResetTransitionLeave() {
      if (this.shouldLock) {
        this.$store.commit("setScrollLocked", true);
        this.shouldLock = false;
      }

      this.resetScrollPosition();
      this.isResetting = false;
    },
    afterResetTransitionEnter() {
      this.hasReset = true;
    },
    bindKeyNavigation() {
      window.addEventListener("keyup", this.onKeyUp, false);
    },
    unbindKeyNavigation() {
      window.removeEventListener("keyup", this.onKeyUp, false);
    },
    onKeyUp(e) {
      switch (e.which) {
        case 9:
          this.onKeyUpTab();
          break;
      }
    },
    onKeyUpTab() {
      // Switch to native mode if users are navigating with tab
      this.isNative = true;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/core.scss";

.SmoothScroll {
  &.is-active {
    .fixed {
      overflow: hidden;

      position: fixed;
      left: 0;
      top: 0;

      width: 100%;
      height: 100%;
    }

    .smooth {
      position: absolute;
      left: 0;
      top: 0;

      width: 100%;
      height: auto;
    }

    .scroll {
      display: block;
      width: 100%;
      height: 100vh;
    }

    &.is-locked {
      .scroll {
        display: none;
      }
    }
  }

  &.is-native {
    .fixed {
      overflow: hidden;
    }

    .smooth {
      transform: none !important;
    }

    &.is-locked {
      .fixed {
        max-height: 100vh;
      }
    }
  }

  // Reset animation

  $pause: 0.2s;

  .reset {
    opacity: 1;
    transform: none;
  }

  @keyframes bounce-out {
    0% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
    20% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translate3d(0, -6vw, 0);
    }
  }

  @keyframes bounce-in {
    0% {
      opacity: 0;
      transform: translate3d(0, 6vw, 0);
    }
    10% {
      opacity: 0;
    }
    60% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  .reset-leave-active {
    animation: bounce-out 0.7s cubic-bezier(0.5, -0.5, 1, 0); //cubic-bezier(0.75, -0.5, 1, 0);
  }

  .reset-enter-active {
    animation: bounce-in 0.8s backwards $pause $ease-out-quart; //cubic-bezier(0, 1.5, 0.75, 1);
  }
}
</style>

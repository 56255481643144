import One from '@/assets/images/collection/progress-report/Progress-Report-1.png'
import Two from '@/assets/images/collection/progress-report/Progress-Report-2.png'
import Three from '@/assets/images/collection/progress-report/Progress-Report-3.png'
import Four from '@/assets/images/collection/progress-report/Progress-Report-4.png'
import OneMobile from '@/assets/images/collection/progress-report/Progress-Report-1-mobile.png'
import TwoMobile from '@/assets/images/collection/progress-report/Progress-Report-2-mobile.png'
import ThreeMobile from '@/assets/images/collection/progress-report/Progress-Report-3-mobile.png'
import FourMobile from '@/assets/images/collection/progress-report/Progress-Report-4-mobile.png'

import ProcessOne from '@/assets/images/collection/progress-report/Progress-Report-Process-1.png'
import ProcessTwo from '@/assets/images/collection/progress-report/Progress-Report-Process-2.png'
import ProcessThree from '@/assets/images/collection/progress-report/Progress-Report-Process-3.png'
import ProcessFour from '@/assets/images/collection/progress-report/Progress-Report-Process-4.png'
import ProcessFive from '@/assets/images/collection/progress-report/Progress-Report-Process-5.png'
import ProcessSix from '@/assets/images/collection/progress-report/Progress-Report-Process-6.png'
import ProcessOneMobile from '@/assets/images/collection/progress-report/Progress-Report-Process-1-mobile.png'
import ProcessTwoMobile from '@/assets/images/collection/progress-report/Progress-Report-Process-2-mobile.png'
import ProcessThreeMobile from '@/assets/images/collection/progress-report/Progress-Report-Process-3-mobile.png'
import ProcessFourMobile from '@/assets/images/collection/progress-report/Progress-Report-Process-4-mobile.png'
import ProcessFiveMobile from '@/assets/images/collection/progress-report/Progress-Report-Process-5-mobile.png'
import ProcessSixMobile from '@/assets/images/collection/progress-report/Progress-Report-Process-6-mobile.png'

import InspectLarge from '@/assets/images/collection/progress-report/Progress-Report-Inspect-Large.png'
import InspectMedium from '@/assets/images/collection/progress-report/Progress-Report-Inspect-Medium.png'
import InspectSmall from '@/assets/images/collection/progress-report/Progress-Report-Inspect-Small.png'
import InspectPortrait from '@/assets/images/collection/progress-report/Progress-Report-Inspect-Portrait.png'

export const getPreviewImages = () => ([
  { src: One, srcMobile: OneMobile },
  { src: Two, srcMobile: TwoMobile },
  { src: Three, srcMobile: ThreeMobile },
  { src: Four, srcMobile: FourMobile },
])

export const getShowcaseImages = () => ([
  { src: ProcessOne, srcMobile: ProcessOneMobile },
  { src: ProcessTwo, srcMobile: ProcessTwoMobile },
  { src: ProcessThree, srcMobile: ProcessThreeMobile },
  { src: ProcessFour, srcMobile: ProcessFourMobile },
  { src: ProcessFive, srcMobile: ProcessFiveMobile },
  { src: ProcessSix, srcMobile: ProcessSixMobile },
  { src: Two, srcMobile: TwoMobile },
  { src: Three, srcMobile: ThreeMobile },
  { src: Four, srcMobile: FourMobile },
])

export const getInspectImage = () => ({
  srcLarge: InspectLarge,
  srcMedium: InspectMedium,
  srcSmall: InspectSmall,
  srcPortrait: InspectPortrait
})

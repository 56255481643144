import One from '@/assets/images/collection/balance/Balance-1.jpg'
import Two from '@/assets/images/collection/balance/Balance-2.jpg'
import Three from '@/assets/images/collection/balance/Balance-3.jpg'
import Four from '@/assets/images/collection/balance/Balance-4.jpg'
import OneMobile from '@/assets/images/collection/balance/Balance-1-mobile.jpg'
import TwoMobile from '@/assets/images/collection/balance/Balance-2-mobile.jpg'
import ThreeMobile from '@/assets/images/collection/balance/Balance-3-mobile.jpg'
import FourMobile from '@/assets/images/collection/balance/Balance-4-mobile.jpg'

import InspectLarge from '@/assets/images/collection/balance/Balance-inspect-large.jpg'
import InspectMedium from '@/assets/images/collection/balance/Balance-inspect-medium.jpg'
import InspectSmall from '@/assets/images/collection/balance/Balance-inspect-small.jpg'
import InspectPortrait from '@/assets/images/collection/balance/Balance-inspect-portrait.jpg'

export const getPreviewImages = () => [
  { src: One, srcMobile: OneMobile },
  { src: Two, srcMobile: TwoMobile },
  { src: Three, srcMobile: ThreeMobile },
  { src: Four, srcMobile: FourMobile },
]

export const getShowcaseImages = () => [
  { src: Two, srcMobile: TwoMobile },
  { src: Three, srcMobile: ThreeMobile },
  { src: Four, srcMobile: FourMobile },
]

export const getInspectImage = () => ({
  srcLarge: InspectLarge,
  srcMedium: InspectMedium,
  srcSmall: InspectSmall,
  srcPortrait: InspectPortrait,
})

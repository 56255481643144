import One from '@/assets/images/collection/piece-18/Piece-18-1.png'
import Two from '@/assets/images/collection/piece-18/Piece-18-2.png'
import Three from '@/assets/images/collection/piece-18/Piece-18-3.png'
import Four from '@/assets/images/collection/piece-18/Piece-18-4.png'

import OneMobile from '@/assets/images/collection/piece-18/Piece-18-1-mobile.png'
import TwoMobile from '@/assets/images/collection/piece-18/Piece-18-2-mobile.png'
import ThreeMobile from '@/assets/images/collection/piece-18/Piece-18-3-mobile.png'
import FourMobile from '@/assets/images/collection/piece-18/Piece-18-4-mobile.png'

import InspectLarge from '@/assets/images/collection/piece-18/Piece-18-Inspect-Large.png'
import InspectMedium from '@/assets/images/collection/piece-18/Piece-18-Inspect-Medium.png'
import InspectSmall from '@/assets/images/collection/piece-18/Piece-18-Inspect-Small.png'
import InspectPortrait from '@/assets/images/collection/piece-18/Piece-18-Inspect-Portrait.png'

export const getPreviewImages = () => ([
  { src: One, srcMobile: OneMobile },
  { src: Two, srcMobile: TwoMobile },
  { src: Three, srcMobile: ThreeMobile },
  { src: Four, srcMobile: FourMobile },
])

export const getShowcaseImages = () => ([
  { src: Two, srcMobile: TwoMobile },
  { src: Three, srcMobile: ThreeMobile },
  { src: Four, srcMobile: FourMobile },
])

export const getInspectImage = () => ({
  srcLarge: InspectLarge,
  srcMedium: InspectMedium,
  srcSmall: InspectSmall,
  srcPortrait: InspectPortrait
})

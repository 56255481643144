<template>
  <div id="app" :class="{ 'is-darkmode': $store.state.isDarkMode }">
    <Preloader v-if="!isLoaded" :onLoaded="onLoaded" />
    <Menu v-if="isMenuOpen" />
    <Shell :isLoading="!isLoaded" />
    <SmoothScroll v-if="isLoaded" ref="scroll" v-slot:default="slotProps">
      <Stage
        :afterEnter="stageAfterEnter"
        :viewProps="{
          scrollToTop: slotProps.scrollToTop,
          setScrollTop: slotProps.setScrollTop,
          isMountingFromPreloader: !isPreloadingComplete,
        }"
      />
    </SmoothScroll>
  </div>
</template>

<script>
import viewport from '@/managers/ViewportManager'
import ticker from '@/managers/TickManager'
import input from '@/managers/InputManager'

import routes from '@/data/routes'

import { Stage } from '@/lib/vue-stage'

import Preloader from '@/views/Preloader.vue'
import Menu from '@/views/Menu.vue'
import Shell from '@/components/Shell.vue'
import SmoothScroll from '@/components/SmoothScroll.vue'

export default {
  name: 'App',
  components: {
    Stage,
    Preloader,
    Menu,
    Shell,
    SmoothScroll,
  },
  data() {
    return {
      isLoaded: false,
      isPreloadingComplete: false,
    }
  },
  computed: {
    isOnDarkView() {
      return this.$route.name === routes.ABOUT
    },
    isMenuOpen() {
      return this.$store.state.isMenuOpen
    },
    isDarkMode() {
      return (this.isOnDarkView || this.isMenuOpen) && this.isLoaded
    },
    isWheeling() {
      return this.$store.state.isWheeling
    },
    isScrolling() {
      return this.$store.state.isScrolling
    },
    vhAtInit() {
      return this.$store.state.viewportHeightAtInit
    },
  },
  watch: {
    isDarkMode: function (next, prev) {
      this.$store.commit('setDarkMode', next)

      // Propogate to html element as well
      if (next) {
        document.documentElement.classList.add('is-darkmode')
      } else {
        document.documentElement.classList.remove('is-darkmode')
      }
    },
    isWheeling: function () {
      this.toggleTitleUpdates()
    },
    isScrolling: function () {
      this.toggleTitleUpdates()
    },
    vhAtInit: function (next, prev) {
      document.documentElement.style.setProperty('--vhAtInit', `${next}px`)
      document.documentElement.style.setProperty(
        '--vhAtInitHalf',
        `${Math.round(next / 2)}px`
      )
    },
  },
  mounted() {
    const { isMobile } = this.$store.state

    viewport.bind()
    ticker.start()
    input.start()

    this.resizeId = viewport.on('resize', this.onResize)

    !isMobile && this.bindTitleUpdates()
    window.addEventListener('beforeunload', this.unbindTitleUpdates, false)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.unbindTitleUpdates, false)
    this.unbindTitleUpdates()

    viewport.off(this.resizeId)

    viewport.unbind()
    ticker.stop()
    input.stop()
  },
  methods: {
    updateViewport() {
      viewport.refresh()
      viewport.apply()
    },
    onLoaded() {
      this.isLoaded = true

      /*
        Push to nextTick so that the next view can determine
        whether it's mounting directly following the Preloader
      */
      this.$nextTick(() => {
        this.isPreloadingComplete = true
      })
    },
    onResize({ width, height }) {
      const { isMobile, isMenuOpen } = this.$store.state

      this.$store.commit('setViewport', { width, height })

      if (isMenuOpen && !isMobile) {
        this.$store.commit('setMenuOpen', false)
      }
    },
    stageAfterEnter() {
      this.updateViewport()
    },
    bindTitleUpdates() {
      this.titleChangeIndex = 0
      clearInterval(this.titleChangeInterval)
      this.titleChangeInterval = setInterval(this.updateDocumentTitle, 800)
      this.updateDocumentTitle()
    },
    resumeTitleUpdates() {
      clearInterval(this.titleChangeInterval)
      this.titleChangeInterval = setInterval(this.updateDocumentTitle, 800)
      this.updateDocumentTitle()
    },
    pauseTitleUpdates() {
      clearInterval(this.titleChangeInterval)
    },
    toggleTitleUpdates() {
      const { isWheeling, isScrolling } = this.$store.state

      if (isWheeling || isScrolling) {
        this.pauseTitleUpdates()
      } else {
        this.resumeTitleUpdates()
      }
    },
    unbindTitleUpdates() {
      clearInterval(this.titleChangeInterval)
      this.updateDocumentTitle(true)
    },
    updateDocumentTitle(reset = false) {
      const TITLE_DEFAULT = 'KELLY MILLIGAN, FINE ART, MADE WITH CODE.'
      const TITLE_PHRASES = [
        'KELLY',
        'MILLIGAN',
        'FINE',
        'ART',
        'MADE',
        'WITH',
        'CODE',
      ]

      if (reset) {
        document.title = TITLE_DEFAULT
        return
      }

      document.title = TITLE_PHRASES[this.titleChangeIndex]
      this.titleChangeIndex += 1
      this.titleChangeIndex =
        this.titleChangeIndex > TITLE_PHRASES.length - 1
          ? 0
          : this.titleChangeIndex
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/reset.scss';
@import '@/styles/fontface.scss';

@import '@/styles/core.scss';

@import '@/styles/scrollbar.scss';

:root {
  --vhAtInit: 100%;
  --vhAtInitHalf: 50%;
}

html,
body,
#app {
  width: 100%;
  min-height: 100vh;

  @include breakpoint(mobile) {
    min-height: var(--vhAtInit);
  }
}

html {
  overflow-y: scroll;
  overscroll-behavior: none;

  background: url('assets/images/tile.png') repeat left top $color-bg;
  background-size: 256px;

  &.is-darkmode {
    background-image: url('assets/images/tile-violet.png');
  }

  @include breakpoint(mobile-tiny) {
    font-size: 15px;
  }
}

body {
  overscroll-behavior: none;
}

html.is-safari-scroll-locked {
  &,
  & body {
    position: fixed;
    overflow: hidden;
  }
}

#app {
  position: relative;

  color: $color-base;
  @include fontRegular();
  font-size: rem(15);
  line-height: 1.6;
  letter-spacing: 0.005em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: url('assets/images/tile.png') repeat left top $color-bg;
  background-size: 256px;

  &.is-darkmode {
    background: url('assets/images/tile-violet.png') repeat left top
      $color-violet;
    background-size: 256px;
  }

  @include breakpoint(mobile) {
    font-size: rem(14);
  }

  section {
    min-width: 100%;
    min-height: 100vh;

    @include breakpoint(mobile) {
      min-height: var(--vhAtInit);
    }
  }

  .Home,
  .Piece {
    background: url('assets/images/tile.png') repeat left top $color-bg;
    background-size: 256px;
  }

  .Menu,
  .About {
    color: $color-white;

    background: url('assets/images/tile-violet.png') repeat left top
      $color-violet;
    background-size: 256px;
  }
}
</style>

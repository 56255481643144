import One from '@/assets/images/collection/microplastics/microplastics-1.png'
import Two from '@/assets/images/collection/microplastics/microplastics-2.png'
import Three from '@/assets/images/collection/microplastics/microplastics-3.png'
import Four from '@/assets/images/collection/microplastics/microplastics-4.png'
import Five from '@/assets/images/collection/microplastics/microplastics-5.png'
import Six from '@/assets/images/collection/microplastics/microplastics-6.png'
import Seven from '@/assets/images/collection/microplastics/microplastics-7.png'
import Eight from '@/assets/images/collection/microplastics/microplastics-8.png'
import OneMobile from '@/assets/images/collection/microplastics/microplastics-1-mobile.png'
import TwoMobile from '@/assets/images/collection/microplastics/microplastics-2-mobile.png'
import ThreeMobile from '@/assets/images/collection/microplastics/microplastics-3-mobile.png'
import FourMobile from '@/assets/images/collection/microplastics/microplastics-4-mobile.png'
import FiveMobile from '@/assets/images/collection/microplastics/microplastics-5-mobile.png'
import SixMobile from '@/assets/images/collection/microplastics/microplastics-6-mobile.png'
import SevenMobile from '@/assets/images/collection/microplastics/microplastics-7-mobile.png'
import EightMobile from '@/assets/images/collection/microplastics/microplastics-8-mobile.png'

import ProcessOne from '@/assets/images/collection/microplastics/microplastics-process-1.png'
import ProcessTwo from '@/assets/images/collection/microplastics/microplastics-process-2.png'
import ProcessThree from '@/assets/images/collection/microplastics/microplastics-process-3.png'
import ProcessFour from '@/assets/images/collection/microplastics/microplastics-process-4.png'
import ProcessFive from '@/assets/images/collection/microplastics/microplastics-process-5.png'
import ProcessSix from '@/assets/images/collection/microplastics/microplastics-process-6.png'
import ProcessSeven from '@/assets/images/collection/microplastics/microplastics-process-7.png'
import ProcessEight from '@/assets/images/collection/microplastics/microplastics-process-8.png'
import ProcessNine from '@/assets/images/collection/microplastics/microplastics-process-9.png'
import ProcessTen from '@/assets/images/collection/microplastics/microplastics-process-10.png'
import ProcessOneMobile from '@/assets/images/collection/microplastics/microplastics-process-1-mobile.png'
import ProcessTwoMobile from '@/assets/images/collection/microplastics/microplastics-process-2-mobile.png'
import ProcessThreeMobile from '@/assets/images/collection/microplastics/microplastics-process-3-mobile.png'
import ProcessFourMobile from '@/assets/images/collection/microplastics/microplastics-process-4-mobile.png'
import ProcessFiveMobile from '@/assets/images/collection/microplastics/microplastics-process-5-mobile.png'
import ProcessSixMobile from '@/assets/images/collection/microplastics/microplastics-process-6-mobile.png'
import ProcessSevenMobile from '@/assets/images/collection/microplastics/microplastics-process-7-mobile.png'
import ProcessEightMobile from '@/assets/images/collection/microplastics/microplastics-process-8-mobile.png'
import ProcessNineMobile from '@/assets/images/collection/microplastics/microplastics-process-9-mobile.png'
import ProcessTenMobile from '@/assets/images/collection/microplastics/microplastics-process-10-mobile.png'

import InspectLarge from '@/assets/images/collection/microplastics/microplastics-inspect-large.png'
import InspectMedium from '@/assets/images/collection/microplastics/microplastics-inspect-medium.png'
import InspectSmall from '@/assets/images/collection/microplastics/microplastics-inspect-small.png'
import InspectPortrait from '@/assets/images/collection/microplastics/microplastics-inspect-portrait.png'

import VerseMp4 from '@/assets/images/collection/microplastics/microplastics-odysseys.mp4'
import VerseWebM from '@/assets/images/collection/microplastics/microplastics-odysseys.webm'

export const getPreviewImages = () => [
  { src: One, srcMobile: OneMobile },
  { src: Two, srcMobile: TwoMobile },
  { src: Three, srcMobile: ThreeMobile },
  { src: Four, srcMobile: FourMobile },
]

export const getShowcaseImages = () => [
  { src: Two, srcMobile: TwoMobile },
  { src: Three, srcMobile: ThreeMobile },
  { src: Four, srcMobile: FourMobile },
  { src: Five, srcMobile: FiveMobile },
  { src: Six, srcMobile: SixMobile },
  { src: Seven, srcMobile: SevenMobile },
  { src: Eight, srcMobile: EightMobile },
]

export const getProcessImages = () => [
  { src: ProcessOne, srcMobile: ProcessOneMobile, aspect: 1 },
  { src: ProcessTwo, srcMobile: ProcessTwoMobile, aspect: 1 },
  { src: ProcessThree, srcMobile: ProcessThreeMobile, aspect: 1 },
  { src: ProcessFour, srcMobile: ProcessFourMobile, aspect: 1 },
  { src: ProcessFive, srcMobile: ProcessFiveMobile, aspect: 1 },
  { src: ProcessSix, srcMobile: ProcessSixMobile, aspect: 1 },
  { src: ProcessSeven, srcMobile: ProcessSevenMobile, aspect: 1 },
  { src: ProcessEight, srcMobile: ProcessEightMobile, aspect: 1 },
  { src: ProcessNine, srcMobile: ProcessNineMobile, aspect: 1 },
  { src: ProcessTen, srcMobile: ProcessTenMobile, aspect: 1 },
]

export const getInspectImage = () => ({
  srcLarge: InspectLarge,
  srcMedium: InspectMedium,
  srcSmall: InspectSmall,
  srcPortrait: InspectPortrait,
})

export const getGalleryVideo = () => ({
  mp4: VerseMp4,
  webm: VerseWebM,
})

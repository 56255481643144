import { MOBILE_BREAKPOINT } from '@/constants'

import * as Balance from '@/assets/images/collection/balance'
import * as StackSlash from '@/assets/images/collection/stackslash'
import * as Retrospect from '@/assets/images/collection/retrospect'
import * as Microplastics from '@/assets/images/collection/microplastics'
import * as ActOfEmotion from '@/assets/images/collection/act-of-emotion'
import * as Terroir from '@/assets/images/collection/terroir'
import * as Ident from '@/assets/images/collection/ident'
import * as Primordial from '@/assets/images/collection/primordial'
import * as Noctiluca from '@/assets/images/collection/noctiluca'
import * as Terraform from '@/assets/images/collection/terraform'
import * as Piece18 from '@/assets/images/collection/piece-18'
import * as StillThinking from '@/assets/images/collection/still-thinking'
import * as ProgressReport from '@/assets/images/collection/progress-report'
import * as Rust from '@/assets/images/collection/rust'
import * as Orbiting from '@/assets/images/collection/orbiting'
import * as Structures from '@/assets/images/collection/structures'

export function getPieceByTitle(title) {
  return collection.filter((piece) => piece.title === title)[0]
}

export function getPieceBySlug(slug) {
  return collection.filter((piece) => piece.slug === slug)[0]
}

export function getNextPieceByIndex(index) {
  return collection[index === collection.length - 1 ? 0 : index + 1]
}

export function getPrevPieceByIndex(index) {
  return collection[index === 0 ? collection.length - 1 : index - 1]
}

export function getNextPieceByTitle(title) {
  for (let i = 0; i < collection.length; i++) {
    if (collection[i].title === title) {
      return getNextPieceByIndex(i)
    }
  }
  console.warn(`Collection: Piece with Title: "${title}" not found...`)
  return null
}

export function getPrevPieceByTitle(title) {
  for (let i = 0; i < collection.length; i++) {
    if (collection[i].title === title) {
      return getPrevPieceByIndex(i)
    }
  }
  console.warn(`Collection: Piece with Title: "${title}" not found...`)
  return null
}

export function getNextPieceBySlug(slug) {
  for (let i = 0; i < collection.length; i++) {
    if (collection[i].slug === slug) {
      return getNextPieceByIndex(i)
    }
  }
  console.warn(`Collection: Piece with Slug: "${slug}" not found...`)
  return null
}

export function getPrevPieceBySlug(slug) {
  for (let i = 0; i < collection.length; i++) {
    if (collection[i].slug === slug) {
      return getPrevPieceByIndex(i)
    }
  }
  console.warn(`Collection: Piece with Slug: "${slug}" not found...`)
  return null
}

export function getPreloadList(isMobile = false) {
  const list = []
  collection.forEach((item) =>
    list.push(...item.previews.map((i) => (isMobile ? i.srcMobile : i.src)))
  )
  return list
}

const parseMountedAsset = ({
  src,
  srcMobile,
  aspect = null,
  scale = null,
}) => ({
  attr: {
    srcSet: `${srcMobile} 500w, ${src} 1000w`,
    sizes: `(max-width: ${MOBILE_BREAKPOINT}px) 200px, 1000px`,
    src,
  },
  src,
  srcMobile,
  aspect,
  scale,
})

const parseMountedAssetList = (assetList) =>
  assetList.map((item) => parseMountedAsset(item))

const parseFullWidthAsset = ({
  srcLarge,
  srcMedium,
  srcSmall,
  srcPortrait,
}) => ({
  attr: {
    srcSet: `${srcLarge} 2200w, ${srcMedium} 1600w, ${srcSmall} 1200w`,
    sizes: '100vw',
    src: srcMedium,
  },
  attrPortrait: {
    src: srcPortrait,
  },
})

const collection = [
  {
    title: 'Balance',
    slug: 'balance',
    year: '2024',
    aspect: 0.75,
    scale: 1,
    previews: parseMountedAssetList(Balance.getPreviewImages()),
    narrative: {
      headline: 'Full Spectrum',
      description: [
        `<em>Balance</em> is a generative collaboration between Kelly Milligan and Amber Vittoria. This series nods to the ever-changing composition of harmony by leveraging Milligan's movement through code and Vittoria's colorful play on equilibrium.`,
        `Even though the composition is set upon mint, the collector can temporarily change the composition of the piece, further speaking to the idea of balance constantly moving.`,
        `Over the course of 2023, Milligan and Vittoria chatted about color, theory, and how to translate Vittoria's painted palette on screen in a textural way. The theme of balance is seen overtly in the composition, but also subtly in the use of gradients and color relationships, creating tension and stability. Every decision in this collection builds to the idea of finding, and changing, one's footing in life.`,
      ],
    },
    showcase: parseMountedAssetList(Balance.getShowcaseImages()),
    process: {
      headline: 'Coming Soon',
      description: [
        '<em>Balance</em> was <a href="https://www.artblocks.io/collections/presents/projects/0x99a9b7c1116f9ceeb1652de04d5969cce509b069/489" target="_blank">released with ArtBlocks</a> on 31 Jan 2024. Additional technical details of the system and colour handling to come.',
      ],
      inspect: parseFullWidthAsset(Balance.getInspectImage()),
    },
  },

  {
    title: 'StackSlash',
    slug: 'stackslash',
    year: '2023',
    aspect: 0.75,
    scale: 1,
    previews: parseMountedAssetList(StackSlash.getPreviewImages()),
    narrative: {
      headline: 'Precious Pieces',
      description: [
        `The exploitation of natural resources presents an inherent imbalance.<br> While profits stack up, the true costs distribute.`,
        `<em>StackSlash</em> utilizes a 2D rigid-body physics engine for composition. Rectangular bodies are placed within a scene and the forces of gravity and collision guide them into a stable resting state.`,
        `The rendering of the system occurs in two parts: While interacting, the bodies are rendered in a 2D canvas to allow fluid manipulation. Once the system arrives in a stable configuration, a heavier WebGL rendering step creates and applies surface textures to the bodies.`,
        `The system is fully responsive and can extend to any viewport size upon interaction. The composition is initially presented in its default 3:4 deterministic state.`,
      ],
    },
    showcase: parseMountedAssetList(StackSlash.getShowcaseImages()),
    process: {
      headline: 'A clean break',
      description: [
        `To provide a unique, natural and raw feeling to the pieces, each rectangle is rendered in the fragment shader, and has a unique texture co-rendered alongside it.`,
        `WebGL has a minumum number of available of texture units of 8, as defined by the spec. Better graphics hardware can provide more, like say 16, however to ensure the system works on as many devices as possible, it's best to stick to 8.`,
        `In order to include unique textures for each piece, the textures are rendered wide-enough to be used across several pieces, then chopped up into their corresponding forms.`,
        `* Only on the tokens with many pieces do the textures get repeated. See if you can spot them!`,
      ],
      showcase: parseMountedAssetList(StackSlash.getProcessImages()),
      inspect: parseFullWidthAsset(StackSlash.getInspectImage()),
    },
  },

  {
    title: 'Retrospect',
    slug: 'retrospect',
    year: '2023',
    aspect: 0.5625,
    scale: 1,
    previews: parseMountedAssetList(Retrospect.getPreviewImages()),
    narrative: {
      headline: 'A light in the dark',
      description: [
        `A soft and peaceful glow, comfort and warmth; <br>a collection of nicer moments.`,
        `Memories catalogued and quietly stacked in the depths of recollection. Some radiate, vivid and accessible. Others remain dormant, awaiting reignition.`,
        `Reflecting and connecting, these experiences mold the best of ourselves. Take stock and take time, uncover and resurface the warm moments temporarily lost to the filing system; in retrospect, there are more of them than you could ever recall.`,
      ],
    },
    showcase: parseMountedAssetList(Retrospect.getShowcaseImages()),
    process: {
      headline: 'Energy Estimated',
      description: [
        `Retrospect utilizes a 2D rigid-body physics engine for composition. Circular bodies are placed within a scene and the forces of gravity, collision and audience intervention guide them into a stable resting state.`,

        `The rendering of the system occurs in two parts: While interacting, the bodies are rendered as outlines in a 2D canvas to allow fluid manipulation. Once the system arrives in a stable configuration, a heavier WebGL rendering step applies physically estimated lighting to each body. This rendering occurs in a fragment shader, where the correct combined colour value for each pixel on the canvas is calculated. "Light" projects outward from each body, the sum of which on a body's "surface" is blended to provide a soft final gradation of light and colour.`,

        `The system is fully responsive and can extend to any viewport size upon interaction. The composition is initially presented in its default 9:16 deterministic state.`,

        `A print renderer allows any composition to be exported beyond normal HTML canvas limits of 16384px. 100 segments of the artwork are rendered and stitched together into a giant and precisely rendered image file 10x the size of what's displayed on-screen.`,
      ],
      showcase: parseMountedAssetList(Retrospect.getProcessImages()),
      inspect: parseFullWidthAsset(Retrospect.getInspectImage()),
    },
  },

  {
    title: 'Micro-plastics',
    slug: 'microplastics',
    year: '2023',
    aspect: 0.7,
    scale: 1,
    previews: parseMountedAssetList(Microplastics.getPreviewImages()),
    narrative: {
      headline: 'The Cost of Convenience',
      description: [
        `The miraculous material properties of plastics make them indispensable.<br>Strong, light, durable, inexpensive.<br>Made robust and made to last, they outlast; destructuring perilously slowly into ever smaller pieces.<br>On behalf of the consumer, our aquatic ecosystems suffer the true long-term cost.`,
        `This system uses a real-time, 2D, rigid body physics engine for composition. Tens of thousands of individual shapes interact, emerging as an unexpected whole.`,
        `Microplastics is a series of 30 works and 4 sketches, released with <a href="https://verse.works/exhibitions/odysseys-kelly-milligan" target="_blank">Verse</a> in February 2023. <br>Six works were exhibited in London as a part of the <em>Odysseys</em> group show. <br>$925 was raised for Sea Shepherd New Zealand.`,
      ],
    },
    showcase: parseMountedAssetList(Microplastics.getShowcaseImages()),
    process: {
      headline: 'Precision in action',
      description: [
        'To use very small objects and still have them physically interact, the physics engine needs a high level of precision. Physics engines generally limit precision in the name of performance: critically important for real-time systems.',
        'By disconnecting render resolution from engine resolution, tiny bodies that collide appropriately become possible.',
        `Microplastics is built upon my "<a href="/piece/primordial">Primordial</a>" toolkit.`,
      ],
      showcase: parseMountedAssetList(Microplastics.getProcessImages()),
      inspect: parseFullWidthAsset(Microplastics.getInspectImage()),
    },
    gallery: {
      sections: [
        {
          name: 'Odysseys Group Show',
          description:
            'Exhibited in London alongside fabulous generative art in Feb 2023',
          videos: Microplastics.getGalleryVideo(),
        },
      ],
    },
  },
  {
    title: 'Act of Emotion',
    slug: 'act-of-emotion',
    year: '2022',
    aspect: 3 / 4,
    scale: 1,
    previews: parseMountedAssetList(ActOfEmotion.getPreviewImages()),
    narrative: {
      headline: 'Acting in Action',
      description: [
        `<em>Act of Emotion</em> investigates digitally-facilitated abstract expressionism: the act of painting itself as a means of expression, extended into mechanical reach.<br>
        A reflection upon the future of automation, machine consciousness, creativity, and humankind’s inevitable consequences. As we await the next leap, we cling desperately to our belief that emotive art is an exclusively human experience.<br>
        Inspired by those that came before—pioneering expressionists and contemporaries alike—the system engages in new methods of painting, free from traditionally accepted physical boundaries and artistic expectations. Action painting for the Connected Age; the system is in perpetual concert, recited in real-time and on-demand.`,

        `Questioned by Colin McCahon, and recontextualised here through a generative lens:<br> 
        <em>How to make a painting beat like, and with, a human heart.</em><br>
        The machine’s movements are precise by default, grounded in mathematics. Organic expression must be introduced by twisting and destabilising; a process of broadening bounds that probes the subconscious of both artist and audience.`,

        `Confrontingly minimal abstract compositions highlight the tension between rigid mechanical precision and fluid human intuition. Striping and stippling, swooping and darting; noisy automatism contrasted with coded instinct.<br> 
        Gestural abstraction applied with simulation of brush and emulation of media. Presence discovered through colour, texture, light and space; the works emerge as physically-manifested in digital form.`,

        `Are these painted works analogous to physical counterparts?<br>
        Can a machine successfully emotionally connect through this practice?<br>
        Let us consider both the intent and the act that realise a painted artwork.`,

        `<em>“Painting is a state of being. Painting is self-discovery.”</em> — Jackson Pollock`,

        `<strong><em><a href="https://www.artblocks.io/collections/curated/projects/0xa7d8d9ef8d8ce8992df33d8b8cf4aebabd5bd270/364" target="_blank">Act of Emotion</a></em> is a Curated release on <a href="https://artblocks.io/" target="_blank">ArtBlocks.io</a>.<br> 
        A series of 400 individual "painted" artworks revealed in September 2022.</strong>`,
      ],
    },
    showcase: parseMountedAssetList(ActOfEmotion.getShowcaseImages()),
    process: {
      headline: 'Bionic Intention',
      description: [
        `Act of Emotion is dependency-free plain javascript and glsl, less than 20kb minified. All graphics are rendered via a simple, bespoke native WebGL renderer which additively paints on each frame. All code is hosted on-chain.`,
        `A square "Palette" canvas is rendered on each frame too, and is sampled onto the main canvas at the "brush" head. The palette navigates with the brush movement, translating and rotating in lockstep to provide a never-ending load of synthetic wet media. Faux lighting is applied at the palette level to create a sense of depth and substance.`,
        `Paths are constructed using cardinal splines with varying tension levels. Adjustments in tension allow the curves to swing drastically and dramatically beyond the points situated along the path.`,
        `The tokens are interactive, by double tapping on the canvas, you can provide your own human touch in the same material as used by the machine.`,
        `The process can be executed for print, enabling exports of up to 36x48" @ 300dpi in size.`,
      ],
      showcase: parseMountedAssetList(ActOfEmotion.getProcessImages()),
      inspect: parseFullWidthAsset(ActOfEmotion.getInspectImage()),
    },
  },
  {
    title: 'Terroir',
    slug: 'terroir',
    year: '2022',
    aspect: 700 / 1000,
    scale: 1,
    previews: parseMountedAssetList(Terroir.getPreviewImages()),
    narrative: {
      headline: 'Sense of Place',
      description: [
        `Fragments of limestone, quartz and clay. <br>Ancient growth and knotted grain. <br>Spark to smoke. <br>Our Star on high. <br>Climate, Soil and Terrain.`,
        `An artwork series exploring emergent natural phenomena, uncovered by harnessing deeply nested fractal noise. A chaotic system where the seed of each iteration acts as a creative engine, generating a window into a foreign world.`,
        `Terroir is my third (and probably final) investigation into the use of rich noise for organic form generation. It builds upon <a href="/piece/rust">Rust</a> and <a href="/piece/terraform">Terraform</a> to both broaden and focus. Elevated through a much wider spectrum, many additional levers, and powerful export controls. It has been finely tuned to consistently generate dramatic and satisfying outcomes.`,
        `Terroir spans 675 editions in total:`,
        `650x on-chain single-edition tokens released in April 2022 on <a href="https://gen.art/drops/terroir" target="_blank">GEN.ART</a>; each collector directly contributing to the series through the hash of their transaction.`,
        `25x untokenized single-edition print editions, to be distributed privately.`,
        `The script will be retired once all 675 editions have been confirmed, only being used for re-creation and maintenance of the existing editions.`,
      ],
    },
    showcase: parseMountedAssetList(Terroir.getShowcaseImages()),
    process: {
      headline: 'Minimise and maximise',
      description: [
        `Scripts that are "on-chain" are uploaded directly to a blockchain. The code can be accessed into the future without change or decay. No hosting, no outside influence; they'll continue to be available as long as that blockchain remains. Uploading code in this way is intensive and expensive. Distilling visual code into as few bytes as possible is an important goal.`,
        `Terroir's code is dependency-free plain javascript and glsl, less than 10kb minified. All graphics are rendered via a simple, bespoke native WebGL renderer and a (quite complex!) fragment shader. On-chain ready!`,
        `Off-chain, an alternate "print" renderer allows for incredibly large exports directly out of the browser using <a href="https://github.com/greggman/dekapng" target="_blank">dekapng</a>. Exports as large as ~800mp, ~40,000px or 3+ metres @ 300dpi are possible with this approach!`,
      ],
      inspect: parseFullWidthAsset(Terroir.getInspectImage()),
    },
    gallery: {
      headline: 'Broaden and focus',
      description: [
        `A series of traits make up each iteration of Terroir:`,
        `Some are foundational, like a procedural colour "base". Others adjust this base, provide specific visual effect or enable special functionality.`,
        `The colour base is algorithmic, sampled from a reduced range of the full 8-bit gamut. The range covers over a million potential outcomes. It's unlikely that any two iterations have exactly the same base unless applied via a trait.`,
        `In combination with the affect of traits, Terroir's variation is practically limitless.`,
      ],
      sections: [
        {
          name: 'Bright',
          description:
            'Shifts the colour range higher, increasing brightness and contrast.',
          items: parseMountedAssetList(Terroir.getBrightImages()),
        },
        {
          name: 'Charcoal',
          description: 'Desaturates while amplifying contrast.',
          items: parseMountedAssetList(Terroir.getCharcoalImages()),
        },
        {
          name: 'Tinted',
          description:
            'Clips colour to the base, creating the effect of ink on tinted paper.',
          items: parseMountedAssetList(Terroir.getTintedImages()),
        },
        {
          name: 'Redshift',
          description:
            'Adjusts the colour range, amplifying the red channel while reducing the others.',
          items: parseMountedAssetList(Terroir.getRedshiftImages()),
        },
        {
          name: 'Moonlit',
          description: 'Washes out all channels and applies a soft blue base.',
          items: parseMountedAssetList(Terroir.getMoonlitImages()),
        },
        {
          name: 'Grid',
          description:
            'Slightly offsets the surface within a series of squares.',
          items: parseMountedAssetList(Terroir.getGridImages()),
        },
        {
          name: 'Disc',
          description:
            'Applies a rotation offset within a series of circular discs.',
          items: parseMountedAssetList(Terroir.getDiscImages()),
        },
        {
          name: 'Spiral',
          description: 'Warps the surface into a spiral.',
          items: parseMountedAssetList(Terroir.getSpiralImages()),
        },
        {
          name: 'Liquify',
          description: 'Reduces scale level to amplify liquid-like forms.',
          items: parseMountedAssetList(Terroir.getLiquifyImages()),
        },
        {
          name: 'Wavy',
          description:
            'Applies a periodic offset to the surface forming uniform ripples.',
          items: parseMountedAssetList(Terroir.getWavyImages()),
        },
        {
          name: 'Choppy',
          description:
            'Applies a periodic offset to the surface forming staggered ripples.',
          items: parseMountedAssetList(Terroir.getChoppyImages()),
        },
        {
          name: 'Explorable',
          description:
            'A very special trait, enabling infinite* exploration of the surface.',
          videos: Terroir.getExplorableVideos(),
        },
      ],
    },
  },
  {
    title: 'Ident',
    slug: 'ident',
    year: '2021',
    aspect: 1000 / 1000,
    scale: 0.7,
    previews: parseMountedAssetList(Ident.getPreviewImages()),
    narrative: {
      headline: 'Unrepeatable signatures',
      description: [
        'Generative identities have always resonated with me. The sheer proliferation potential is impressive, and elegant use of technical complexity can make something especially unique.',
        `With this ident I wanted the ability to generate unique variations on demand, like any time I want to sign a piece of work! It's playful and fun, it makes me happy. It's built upon my "<a href="/piece/primordial">Primordial</a>" toolkit.`,
      ],
    },
    showcase: parseMountedAssetList(Ident.getShowcaseImages()),
    process: {
      headline: 'The problem with letterforms',
      description: [
        'Typography presents unique challenges in generative work. Licensing concerns aside, it can be challenging to load and render font files in graphical environments. The browser is language-first, the written word. Making art in the browser enables use of the humble webfont to render typography through the canvas 2d context.',
        `Physics engines generally prefer convex shapes. In order to make up a more complex shape - for example: with concave areas, holes, or multiple pieces - they need to be broken down into a group of simpler shapes and stuck together with constraints to create the final form. Tools for automating this "decomposition" work well but require a simple, closing, non-self-intersecting path to work effectively.`,
        `Consider any letterform with a hole in it's glyph, like "B", "p", "Q", "8", these have closed paths that also have areas cut out of them. Each of these characters needs to be meticulously edited in vector-format to add additional cuts and slits and connections that form a single closed path. For this Ident, a compendium of individually edited SVG glyphs was required in order to achieve accurate physical interactions.`,
      ],
      showcase: parseMountedAssetList(Ident.getProcessImages()),
    },
  },
  {
    title: 'Primordial',
    slug: 'primordial',
    year: '2020',
    aspect: 1000 / 1000,
    scale: 0.7,
    previews: parseMountedAssetList(Primordial.getPreviewImages()),
    narrative: {
      headline: 'A toolkit for interactive composition',
      description: [
        'Primordial is a real-time and interactive toolkit for creating complex compositions of simple forms. At its heart is a physics engine, allowing each individual form to physically interact with those around it. Forms can be added to the world, dragged, thrown, and remixed at will.',
        'Interactive re-composition allows direct collaboration between artist, audience, and generative system.',
        `Primordial is the base for other works of mine, including <a href="/piece/microplastics">Microplastics</a> and my <a href="/piece/ident">personal brand identity</a>.`,
      ],
    },
    showcase: parseMountedAssetList(Primordial.getShowcaseImages()),
    process: {
      headline: 'Powerful and full of promise',
      description: [
        'The physical nature of the system makes complex art from simple ideas, and offers simple implementations for complex ones.',
        'I continue to evolve the toolkit, adding new shapes (like typographic forms), physical affects (like gravity and other forces), and different ways of spawning actors.',
        "I've only scratched the surface with this system, there's so much more to uncover.",
      ],
      showcase: parseMountedAssetList(Primordial.getProcessImages()),
    },
  },
  {
    title: 'Noctiluca',
    slug: 'noctiluca',
    year: '2020',
    aspect: 1600 / 800,
    scale: 1,
    previews: parseMountedAssetList(Noctiluca.getPreviewImages()),
    narrative: {
      headline: 'Luminescence in bloom',
      description: [
        'Noctiluca is inspired by moonlit sandy shorelines and waves laden with bioluminescent cells.',
        "This series started as a study into uniform distributions. The goal with these algorithms is to place points across the canvas without any touching each other. The distribution infuses the piece with intention, where points aren't just simply placed at random; it enhances both texture and contrast.",
      ],
    },
    showcase: parseMountedAssetList(Noctiluca.getShowcaseImages()),
    process: {
      headline: 'The details are in the distribution',
      description: [
        'For the distribution of points I studied Quadtrees and Octrees, a data structure for querying points within a coordinate space.',
        "Quadtrees (2D) and Octrees (3D) work by recursively segmenting the space based on how many points are within each area. Segmentation allows us to query only a small subset of points as needed, drastically increasing the efficiency of point comparisons (in this case, to pick a random point on the canvas and use it if there isn't already a point in that space) compared with naive n^2 sampling.",
        'Another popular algorithm for achieving a uniform distribution is Poisson Disc sampling.',
      ],
      showcase: parseMountedAssetList(Noctiluca.getProcessImages()),
      inspect: parseFullWidthAsset(Noctiluca.getInspectImage()),
    },
  },
  {
    title: 'Terraform',
    slug: 'terraform',
    year: '2019',
    aspect: 1000 / 1414,
    scale: 1,
    previews: parseMountedAssetList(Terraform.getPreviewImages()),
    narrative: {
      headline: 'Fall Colours in Ontario',
      description: [
        `As a sequel to my explorations in <a href="/piece/rust">Rust</a>, this piece uses layers of nested noise to aim for an intensely organic mix of texture and colour.`,
        'While living in Toronto, some dear friends of ours moved to the city. Throughout autumn we spent several weekends enjoying the “Fall Colours” in Ontario, including a long weekend at a stunning cottage near Algonquin Park, the cosiest of times. This shared experience was the inspiration for this colour palette, and their desire to hang something of mine on their wall was the push I needed to dust off the old Rust code, improve it, and create the Terraform series.',
      ],
    },
    showcase: parseMountedAssetList(Terraform.getShowcaseImages()),
    process: {
      headline: 'Searching for an organic mix',
      description: [
        'A big challenge with computer-generated art is achieving something that looks organic.',
        'Mathematical models and algorithms, even noise, fundamentally operate within a deterministic framework. Computer Graphics are rigid, they are grounded in math, and it’s hard to break through the barrier of something that looks like “Computer Graphics” into something that more closely resembles nature.',
        'The texture and colour in this series attempts to break through by deeply nesting Fractal Brownian Motion (FBM) noise, reaching a point where discernible patterns melt away.',
      ],
      inspect: parseFullWidthAsset(Terraform.getInspectImage()),
    },
    // sales: {
    //   pitch: [
    //     'Terraform is a series of 20 unique editions. Each is an original artwork, a one-of-a-kind single-edition print and will not be reproduced.',
    //     `Each edition includes a Certificate of Authenticity, generatively signed using unique "fingerprints" of the source algorithm, co-generated alongside the edition.`,
    //     'The print is produced to archival fine art standards using pigment-based inks on thick, highly textured Hahnemühle German Etching paper.',
    //     'The printing process is expertly executed by ThePrintSpace in London, the best of the best.',
    //   ],
    //   specimens: parseMountedAssetList(Terraform.getSalesImages()),
    //   specs: {
    //     dimensions: [
    //       '1000x700mm',
    //       '10x Landscape editions',
    //       '10x Portrait editions',
    //     ],
    //     price: ['$850'],
    //     printing: [
    //       'Fine art giclée',
    //       'Pigment-based inks',
    //       'Archival standard',
    //     ],
    //     paper: [
    //       'Hahnemühle German Etching',
    //       '310 gsm, 100% α-cellulose',
    //       'Matte, highly textured',
    //     ],
    //   },
    // },
  },
  {
    title: 'Piece 18',
    slug: 'piece-18',
    year: '2018',
    aspect: 1000 / 1414,
    scale: 1,
    previews: parseMountedAssetList(Piece18.getPreviewImages()),
    narrative: {
      headline: '17 Global Goals',
      description: [
        `In 2018 Denkwerk from Köln created the digital experience for <a href="https://art4globalgoals.com/" target="_blank">Art4GlobalGoals</a>, a response to the UN’s Global Goals for a better world by 2030. A collaboration with the painter Leon Löwentraut, Art4GlobalGoals allowed users to “paint” through 17 goals with their cursor. The data for these painting gestures was captured by the digital experience.`,
        'I worked with Denkwerk to create “Piece 18”, an artistic petition formed by the interactions of these users.',
      ],
    },
    showcase: parseMountedAssetList(Piece18.getShowcaseImages()),
    process: {
      headline: 'Painting with circles',
      description: [
        'A massive challenge with this project was creating brush strokes that mimic the texture of real paint.',
        'A lot of my work uses circles, arranging vast numbers of them into the desired form.',
        'Each individual user gesture was plotted and normalised into a set of points. The digital “paint brush” then steps over each point on a gesture path, stippling circles of varying size along and across the points throughout the path. Using noise and randomness, the stippling starts to create highly varied and unique looking brush strokes for each gesture.',
        `Progression of the path adjustment, brush texture, and colour mixing can be seen in <a href="/piece/progress-report">Progress Report</a>.`,
      ],
      inspect: parseFullWidthAsset(Piece18.getInspectImage()),
    },
  },
  {
    title: 'Still Thinking',
    slug: 'still-thinking',
    year: '2018',
    aspect: 1414 / 1000,
    scale: 0.75,
    previews: parseMountedAssetList(StillThinking.getPreviewImages()),
    narrative: {
      headline: 'Real-time and for-print',
      description: [
        'A generative branding device created for Thinkingbox, this piece required 2 components: a real-time interactive graphics system and a print-resolution exporter.',
        'The real-time piece is an integral part of the Thinkingbox website, running and shifting constantly in the background. Users can interact directly with the piece using their cursor, adjusting the colour, texture and shift of the waves in real-time, across all devices.',
        'The print exporter steps through the same algorithm at 300dpi resolution and up to B0 in size (1414x1000mm), rendering razor sharp output for use in print and traditional branding materials like business cards and print advertising.',
      ],
    },
    showcase: parseMountedAssetList(StillThinking.getShowcaseImages()),
    process: {
      headline: 'Rendering over time',
      description: [
        'The real-time requirements of this piece demanded a highly performant solution; something which could constantly run in a full viewport WebGL canvas without slowing down the experience.',
        'In order to achieve a large amount of fine strand detail, a series of points are moved and then drawn each frame, without clearing previous canvas content. These points act as the “heads” of the strands, growing in length over the course of a series of frames. This allows the canvas to be completely filled with fine detail, while each frame has relatively low demands: rendering a series of instanced planes, moved in small increments per frame.',
      ],
      inspect: parseFullWidthAsset(StillThinking.getInspectImage()),
    },
  },
  {
    title: 'Progress Report',
    slug: 'progress-report',
    year: '2018',
    aspect: 1000 / 1414,
    scale: 1,
    previews: parseMountedAssetList(ProgressReport.getPreviewImages()),
    narrative: {
      headline: 'Finding the right mix',
      description: [
        `Progress Report provides a glimpse of the process in creating <a href="/piece/piece-18">Piece 18</a>.`,
        'Normalisation of paths, brush texture, and mixing of “paint” colour were all critical elements of this project. When I first arrived at these red and blue colour-mix tests I was enamoured with the result. These were some of my absolute favourite outputs of the generative system, and live alongside the final piece as equally beautiful in my eyes.',
      ],
    },
    showcase: parseMountedAssetList(ProgressReport.getShowcaseImages()),
    process: {
      headline: 'Primary',
      description: [
        'Achieving a colour mixing effect which resembles real-world wet paint is a tough ask.',
        'Once satisfied with the texture of the brush strokes, the next major hurdle was having paint colours mix together. Before each gesture is drawn, the algorithm steps along its path, reading and storing what colours have already been painted onto the canvas. The gesture is then drawn, mixing in the stored colour values over pickup and decay ranges. With a full colour spectrum, the colours eventually mix toward a medium brown, just as I would expect with real paint.',
      ],
      inspect: parseFullWidthAsset(ProgressReport.getInspectImage()),
    },
  },
  {
    title: 'Rust',
    slug: 'rust',
    year: '2017',
    aspect: 1414 / 1000,
    scale: 0.85,
    previews: parseMountedAssetList(Rust.getPreviewImages()),
    narrative: {
      headline: 'Foreign surfaces',
      description: [
        'Rust explores the uncharted space of foreign worlds that emerge from deeply nested noise functions.',
        'Resembling satellite imagery of sci-fi fantasy, the patterns formed resemble topography and wild weather systems. Dosed with fantastical colour, the images appear highly alien, glimpses of a far away cosmos, waiting to be explored.',
      ],
    },
    showcase: parseMountedAssetList(Rust.getShowcaseImages()),
    process: {
      headline: 'Deep nesting',
      description: [
        'Fractal Brownian Motion (FBM) is a technique of nesting octaves of noise to create highly detailed and organic noise curves. Being fractal in nature, these curves provide a bridge for us to mimic some of the natural fractal phenomena seen in our world, like erosion patterns.',
        'By heavily nesting FBM functions within each other, the noise generated becomes incredibly unique, unpredictable and high in contrast.',
        'In Rust the noise is pushed beyond being a tool for composition, instead it is a vehicle for chaotic creation.',
      ],
      inspect: parseFullWidthAsset(Rust.getInspectImage()),
    },
  },
  {
    title: 'Orbiting',
    slug: 'orbiting',
    year: '2017',
    aspect: 1000 / 1414,
    scale: 1,
    previews: parseMountedAssetList(Orbiting.getPreviewImages()),
    narrative: {
      headline: 'Scribbling with glass',
      description: [
        'What started out as an exploration of orbiting a point in 3D space resulted in this piece. My initial goal was simply to understand how to place a series of points in a spiral along the vertical axis.',
        'This is a 2D rendering environment, the Z position of each point is made apparent by reducing the size and transparency of the points along the path. Noise is introduced to transform the result from a rigid spiral into a fluid looking scribble down the canvas.',
        'Colour shifting, transparency and simple reflections are applied as it’s drawn, providing an effect that hints at hand-blown stained glass, including some physical imperfection.',
      ],
    },
    showcase: parseMountedAssetList(Orbiting.getShowcaseImages()),
    process: {
      headline: 'Polishing pixels',
      description: [
        'To add a smooth, shiny, polished surface to the scribble, highly transparent circles are drawn in series along the entire path. The combination of many circles, slowly changing in radius and colour is what provides the transparent glass-like effect, getting seemingly darker in areas where the glass is thicker. A final reflective layer is added to the top half of each circle to add the shiny depth that completes the effect.',
      ],
      inspect: parseFullWidthAsset(Orbiting.getInspectImage()),
    },
  },
  {
    title: 'Structures',
    slug: 'structures',
    year: '2016',
    aspect: 1000 / 1414,
    scale: 1,
    previews: parseMountedAssetList(Structures.getPreviewImages()),
    narrative: {
      headline: 'Isometric Aztecs',
      description: [
        'Structures is one of my earlier attempts at creating art in the browser. It started as a random arrangement of triangles, aligned to the top, centre and bottom of the canvas. By applying blending to the triangles, an interesting and abstract light refraction effect emerged within, like a series of angled mirrors. The triangles were swapped with 2D prisms, adding additional depth and a pseudo isometric 3D feel to the final output.',
      ],
    },
    showcase: parseMountedAssetList(Structures.getShowcaseImages()),
    process: {
      headline: 'The final blend',
      description: [
        'Blending modes are commonplace in computer graphics software, determining how layers of colour interact; in this case, how each new prism appears on top of previously drawn prisms.',
        'This interaction of light and dark allows unexpected results to emerge. The blending creates impossible architectural depth, while still being recognisable by human eyes as structural and physical.',
      ],
      inspect: parseFullWidthAsset(Structures.getInspectImage()),
    },
  },
]

export default collection

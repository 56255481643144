import Vue from 'vue'
import Vuex from 'vuex'

import { MOBILE_BREAKPOINT } from '@/constants'

import collection from '@/data/collection'

import viewport from '@/managers/ViewportManager'

Vue.use( Vuex )

const isMobile = ( width ) => ( width <= MOBILE_BREAKPOINT )

export default new Vuex.Store({
  state: {
    isWheeling: false,
    isScrolling: false,
    isScrollShallow: true,
    isScrollLocked: false,
    isDarkMode: false,
    isMenuOpen: false,
    isMobile: isMobile( viewport.latest.width ),
    viewportWidth: viewport.latest.width,
    viewportHeight: viewport.latest.height,
    viewportHeightAtInit: -1,
    activeGroupIndex: 0,
    hintingHasScrolled: false,
    hintingHasSelected: false
  },
  mutations: {
    setWheeling: ( state, isWheeling ) => {
      state.isWheeling = isWheeling
    },
    setScrolling: ( state, isScrolling ) => {
      state.isScrolling = isScrolling
    },
    setScrollShallow: ( state, isShallow ) => {
      state.isScrollShallow = isShallow
    },
    setScrollLocked: ( state, isLocked ) => {
      state.isScrollLocked = isLocked
    },
    setDarkMode: ( state, isDarkMode ) => {
      state.isDarkMode = isDarkMode
    },
    setMenuOpen: ( state, isOpen ) => {
      state.isMenuOpen = isOpen
    },
    setViewport: ( state, { width, height } ) => {
      state.viewportWidth = width
      state.viewportHeight = height

      if ( state.viewportHeightAtInit === -1 ) {
        state.viewportHeightAtInit = height
      }
      state.isMobile = isMobile( width )
    },
    setActiveGroupIndex: ( state, index ) => {
      state.activeGroupIndex = index
    },
    setActiveGroupIndexBySlug: ( state, slug ) => {
      state.activeGroupIndex = collection
        .map( ( piece, index ) => (slug === piece.slug ? index : null ))
        .filter(index => ( index !== null ))[ 0 ]
    },
    setHintingHasScrolled: ( state ) => {
      state.hintingHasScrolled = true
    },
    setHintingHasSelected: ( state ) => {
      state.hintingHasSelected = true
    }
  }
})

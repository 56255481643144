/*
  Simple class for preloading images:
    - Can load a single image by path or an array of paths
    - Internally holds references to images to avoid garbage collection
    - Items can be manually unloaded by path
    - Provides onLoaded and onProgress (for array) callbacks
*/

const DEBUG = false
const SIMULATE = false
const SIMULATE_LOAD_DURATION = 100 + 300 * Math.random()
let simulatorTime = 0

export default class ImagePreloader {

  collection = []

  get loaded() { return this.collection }

  load( src, onLoaded, onProgress ) {

    if ( typeof src === 'string' ) {
      this._loadSingle( src, onLoaded )
    }
    else if ( Array.isArray( src ) ) {
      this._loadArray( src, onLoaded, onProgress )
    }
    else {
      console.warn( 'ImageLoader.load(): src was not a string or array of strings...' )
    }
  }

  unload( src ) {

    delete this.collection[ src ]
  }

  _loadArray(
    srcList,
    onLoaded = () => {},
    onProgress = () => {}
  ) {

    DEBUG && console.log( 'ImageLoader._loadArray():', srcList )

    const count = srcList.length
    let loaded = 0

    srcList.forEach(
      src => this._loadSingle( src, () => {
        loaded += 1
        onProgress( loaded / count )
        if ( loaded === count ) {
          onLoaded()
        }
      } )
    )
  }

  _loadSingle( src, onLoaded ) {

    DEBUG && console.log( 'ImageLoader._loadSingle():', src )

    // Prevent duplicate paths from being re-loaded
    if ( this.collection[ src ] !== undefined ) {
      onLoaded()
      return
    }

    this.collection[ src ] = new Image()

    if ( SIMULATE ) {
      setTimeout( onLoaded, simulatorTime + SIMULATE_LOAD_DURATION * Math.random() )
      simulatorTime += SIMULATE_LOAD_DURATION
    } else {
      this.collection[ src ].onload = onLoaded
    }

    this.collection[ src ].src = src
  }
}

import One from '@/assets/images/collection/terraform/Terraform-1.png'
import Two from '@/assets/images/collection/terraform/Terraform-2.png'
import Three from '@/assets/images/collection/terraform/Terraform-3.png'
import Four from '@/assets/images/collection/terraform/Terraform-4.png'
import Five from '@/assets/images/collection/terraform/Terraform-5.png'
import Six from '@/assets/images/collection/terraform/Terraform-6.png'
import OneMobile from '@/assets/images/collection/terraform/Terraform-1-mobile.png'
import TwoMobile from '@/assets/images/collection/terraform/Terraform-2-mobile.png'
import ThreeMobile from '@/assets/images/collection/terraform/Terraform-3-mobile.png'
import FourMobile from '@/assets/images/collection/terraform/Terraform-4-mobile.png'
import FiveMobile from '@/assets/images/collection/terraform/Terraform-5-mobile.png'
import SixMobile from '@/assets/images/collection/terraform/Terraform-6-mobile.png'

import InspectLarge from '@/assets/images/collection/terraform/Terraform-Inspect-Large.png'
import InspectMedium from '@/assets/images/collection/terraform/Terraform-Inspect-Medium.png'
import InspectSmall from '@/assets/images/collection/terraform/Terraform-Inspect-Small.png'
import InspectPortrait from '@/assets/images/collection/terraform/Terraform-Inspect-Portrait.png'

import SalesOne from '@/assets/images/collection/terraform/Terraform-Sales-1.png'
import SalesOneMobile from '@/assets/images/collection/terraform/Terraform-Sales-1-mobile.png'

export const getPreviewImages = () => ([
  { src: One, srcMobile: OneMobile },
  { src: Two, srcMobile: TwoMobile },
  { src: Four, srcMobile: FourMobile },
  { src: Six, srcMobile: SixMobile },
])

export const getShowcaseImages = () => ([
  { src: Two, srcMobile: TwoMobile },
  { src: Three, srcMobile: ThreeMobile, aspect: 1414 / 1000 },
  { src: Four, srcMobile: FourMobile },
  { src: Five, srcMobile: FiveMobile, aspect: 1414 / 1000 },
  { src: Six, srcMobile: SixMobile },
])

export const getInspectImage = () => ({
  srcLarge: InspectLarge,
  srcMedium: InspectMedium,
  srcSmall: InspectSmall,
  srcPortrait: InspectPortrait
})

export const getSalesImages = () => ([
  { src: SalesOne, srcMobile: SalesOneMobile }
])

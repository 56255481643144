import One from '@/assets/images/collection/act-of-emotion/act-of-emotion-1.jpg'
import Two from '@/assets/images/collection/act-of-emotion/act-of-emotion-2.jpg'
import Three from '@/assets/images/collection/act-of-emotion/act-of-emotion-3.jpg'
import Four from '@/assets/images/collection/act-of-emotion/act-of-emotion-4.jpg'
import OneMobile from '@/assets/images/collection/act-of-emotion/act-of-emotion-1-mobile.jpg'
import TwoMobile from '@/assets/images/collection/act-of-emotion/act-of-emotion-2-mobile.jpg'
import ThreeMobile from '@/assets/images/collection/act-of-emotion/act-of-emotion-3-mobile.jpg'
import FourMobile from '@/assets/images/collection/act-of-emotion/act-of-emotion-4-mobile.jpg'

import InspectLarge from '@/assets/images/collection/act-of-emotion/act-of-emotion-inspect-large.jpg'
import InspectMedium from '@/assets/images/collection/act-of-emotion/act-of-emotion-inspect-medium.jpg'
import InspectSmall from '@/assets/images/collection/act-of-emotion/act-of-emotion-inspect-small.jpg'
import InspectPortrait from '@/assets/images/collection/act-of-emotion/act-of-emotion-inspect-portrait.jpg'

import ProcessOne from '@/assets/images/collection/act-of-emotion/act-of-emotion-process-1.jpg'
import ProcessTwo from '@/assets/images/collection/act-of-emotion/act-of-emotion-process-2.jpg'
import ProcessThree from '@/assets/images/collection/act-of-emotion/act-of-emotion-process-3.jpg'
import ProcessFour from '@/assets/images/collection/act-of-emotion/act-of-emotion-process-4.jpg'
import ProcessOneMobile from '@/assets/images/collection/act-of-emotion/act-of-emotion-process-1-mobile.jpg'
import ProcessTwoMobile from '@/assets/images/collection/act-of-emotion/act-of-emotion-process-2-mobile.jpg'
import ProcessThreeMobile from '@/assets/images/collection/act-of-emotion/act-of-emotion-process-3-mobile.jpg'
import ProcessFourMobile from '@/assets/images/collection/act-of-emotion/act-of-emotion-process-3-mobile.jpg'

export const getPreviewImages = () => [
  { src: One, srcMobile: OneMobile },
  { src: Two, srcMobile: TwoMobile },
  { src: Three, srcMobile: ThreeMobile },
  { src: Four, srcMobile: FourMobile },
]

export const getShowcaseImages = () => [
  { src: Two, srcMobile: TwoMobile },
  { src: Three, srcMobile: ThreeMobile },
  { src: Four, srcMobile: FourMobile },
]

export const getProcessImages = () => [
  { src: ProcessOne, srcMobile: ProcessOneMobile },
  { src: ProcessTwo, srcMobile: ProcessTwoMobile },
  { src: ProcessThree, srcMobile: ProcessThreeMobile },
  { src: ProcessFour, srcMobile: ProcessFourMobile, aspect: 16 / 9 },
]

export const getInspectImage = () => ({
  srcLarge: InspectLarge,
  srcMedium: InspectMedium,
  srcSmall: InspectSmall,
  srcPortrait: InspectPortrait,
})

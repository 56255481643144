<template>
  <div class="Shell" :class="{ 'is-darkmode': $store.state.isDarkMode }">

    <div class="left-top">
      <transition name="link">
        <div class="home" v-if="isHomeVisible">
          <router-link
            class="name"
            :to="{ name: ROUTES.HOME }"
            @mouseenter.native="onMouseEnterHome"
            @touchstart.native="onMouseEnterHome"
            :class="{ 'is-animating-hover': hoverHome.flag }"
          >
            <span>KELLY MILLIGAN</span>
          </router-link>
        </div>
      </transition>
      <transition name="link">
        <a
          class="menu"
          v-if="!isLoading && isMenuVisible"
          @click="onMenuClick"
          @mouseenter="onMouseEnterMenu"
          @touchstart="onMouseEnterMenu"
          :class="{ 'is-animating-hover': hoverMenu.flag }"
        >
          <span>MENU</span>
        </a>
      </transition>
    </div>

    <div class="left-bottom">
      <transition name="link">
        <a
          class="contact"
          href="mailto:kellymilligan.art@gmail.com"
          target="_blank"
          v-if="!isLoading && isContactVisible"
          @mouseenter="onMouseEnterContact"
          @touchstart="onMouseEnterContact"
          :class="{ 'is-animating-hover': hoverContact.flag }"
        >
          <span>CONTACT</span>
        </a>
      </transition>
    </div>

    <div class="right-top">
      <transition name="link">
        <a
          class="close"
          v-if="!isLoading && isCloseVisible"
          @click="onCloseClick"
          @mouseenter="onMouseEnterClose"
          @touchstart="onMouseEnterClose"
          :class="{ 'is-animating-hover': hoverClose.flag }"
        >
          <span>CLOSE</span>
        </a>
      </transition>
    </div>

    <div class="right-bottom">
      <transition name="link" mode="out-in">
        <p
          class="loading"
          v-if="isLoading && flashLoadingLabel"
        >
          LOADING
        </p>
        <router-link
          class="about"
          :to="{ name: ROUTES.ABOUT }"
          v-else-if="!isLoading && isAboutVisible"
          @mouseenter.native="onMouseEnterAbout"
          @touchstart.native="onMouseEnterAbout"
          :class="{ 'is-animating-hover': hoverAbout.flag }"
        >
          <span>ABOUT</span>
        </router-link>
        <router-link
          class="legal"
          :to="{ name: ROUTES.LEGAL }"
          v-else-if="!isLoading && isLegalVisible"
          @mouseenter.native="onMouseEnterLegal"
          @touchstart.native="onMouseEnterLegal"
          :class="{ 'is-animating-hover': hoverLegal.flag }"
        >
          <span>LEGAL</span>
        </router-link>
      </transition>
    </div>

  </div>
</template>

<script>

import routes from '@/data/routes'

export default {
  name: 'Shell',
  props: {
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  components: {},
  data() {
    return {
      ROUTES: routes,
      flashLoadingLabel: false,
      hoverHome: { flag: false, timer: null },
      hoverMenu: { flag: false, timer: null },
      hoverContact: { flag: false, timer: null },
      hoverClose: { flag: false, timer: null },
      hoverAbout: { flag: false, timer: null },
      hoverLegal: { flag: false, timer: null },
    }
  },
  mounted() {
    this.flashInterval = setInterval( () => {
      this.flashLoadingLabel = !this.flashLoadingLabel
      if ( !this.isLoading ) {
        clearInterval( this.flashInterval )
      }
    }, 400 )

    this.bindKeyNavigation()
  },
  beforeDestroy() {

    this.unbindKeyNavigation()
  },
  computed: {
    isCloseVisible() {
      const { name } = this.$route
      const { isMobile, isMenuOpen } = this.$store.state
      return (
        name === routes.ABOUT ||
        name === routes.PIECE ||
        name === routes.LEGAL ||
        ( isMobile && isMenuOpen )
      )
    },
    isMenuVisible() {
      const { isMobile, isMenuOpen } = this.$store.state
      return (
        ( isMobile && !isMenuOpen )
      )
    },
    isAboutVisible() {
      const { isMobile } = this.$store.state
      return (
        !isMobile &&
        this.$route.name !== routes.ABOUT
      )
    },
    isLegalVisible() {
      const { isMobile } = this.$store.state
      return (
        !isMobile &&
        this.$route.name === routes.ABOUT
      )
    },
    isContactVisible() {
      const { isMobile } = this.$store.state
      return !isMobile
    },
    isHomeVisible() {
      const { isMobile } = this.$store.state
      return (
        !isMobile ||
        ( isMobile && this.isLoading )
      )
    },
  },
  methods: {
    onCloseClick() {
      const { isMobile, isMenuOpen } = this.$store.state

      if ( isMobile && isMenuOpen ) {
        this.$store.commit( 'setMenuOpen', false )
      } else if (
        this.$route.name === routes.ABOUT ||
        this.$route.name === routes.LEGAL ) {
        this.$router.go( -1 )
      } else {
        this.$router.push( { name: routes.HOME } )
      }
    },
    onMenuClick() {
      this.$store.commit( 'setMenuOpen', true )
    },
    runHoverAnimation( instance ) {
      if ( instance.flag ) return
      instance.flag = true
      clearTimeout( instance.timer )
      instance.timer = setTimeout( () => { instance.flag = false }, 600 )
    },
    onMouseEnterHome() {
      this.runHoverAnimation( this.hoverHome )
    },
    onMouseEnterMenu() {
      this.runHoverAnimation( this.hoverMenu )
    },
    onMouseEnterContact() {
      this.runHoverAnimation( this.hoverContact )
    },
    onMouseEnterClose() {
      this.runHoverAnimation( this.hoverClose )
    },
    onMouseEnterAbout() {
      this.runHoverAnimation( this.hoverAbout )
    },
    onMouseEnterLegal() {
      this.runHoverAnimation( this.hoverLegal )
    },
    bindKeyNavigation() {
      window.addEventListener( 'keyup', this.onKeyUp, false )
    },
    unbindKeyNavigation() {
      window.removeEventListener( 'keyup', this.onKeyUp, false )
    },
    onKeyUp( e ) {
      switch ( e.which ) {
        case 27:
          this.onKeyUpEsc()
          break
      }
    },
    onKeyUpEsc() {
      if ( this.isCloseVisible ) {
        this.onCloseClick()
      }
    }
  }
}
</script>

<style scoped lang="scss">

  @import '@/styles/core.scss';

  $horiz-margin: rem( 15 );
  $verti-margin: rem( 7 );

  $horiz-margin-large: rem( 25 );
  $verti-margin-large: rem( 13 );

  $horiz-margin-max: rem( 35 );
  $verti-margin-max: rem( 22 );

  $horiz-margin-mobile: rem( 10 );
  $verti-margin-mobile: rem( 5 );

  .Shell {

    .left-top,
    .left-bottom,
    .right-top,
    .right-bottom {

      position: fixed;

      z-index: $z-index-shell;

      font-size: rem( 10 );
      @include fontMedium();
      letter-spacing: 0.05em;

      user-select: none;

      @media only screen and (min-width: 1680px) {
        font-size: rem( 11 );
      }

      @media only screen and (min-width: 2200px) {
        font-size: rem( 12 );
      }

      @include breakpoint( mobile ) {

        font-size: rem( 10 );

      }

      a {

        display: inline-block;
        padding: rem( 30 );
        text-decoration: none;

        cursor: pointer;

        @include breakpoint( mobile ) {

          padding: rem( 15 );

        }

        span {

          display: block;

          color: $color-black;

        }

        &.is-animating-hover {

          span {

            @keyframes link-hover {
              0% {
                color: $color-violet;
                transform: translate3d( 0, rem( 5 ), 0 );
              }
              20% {
                color: $color-violet;
              }
              20.1% {
                color: $color-black;
              }
              100% {
                color: $color-black;
                transform: none;
              }
            }

            animation: link-hover both 0.6s $ease-out-quart;

          }

        }

      }

      p {

        padding: rem( 30 );

        @include breakpoint( mobile ) {

          padding: rem( 15 );

        }

      }

      a,
      p {

        &.link-enter-active {
          transition: transform 0.8s 0.4s $ease-out-quart, opacity 0s 0.4s;
        }
        &.link-enter {
          opacity: 0;
          transform: translate3d( 0, rem( 5 ), 0 );
        }

      }

    }

    .left-top {

      left: $horiz-margin;
      top: $verti-margin;

      @media only screen and (min-width: 1680px) {
        left: $horiz-margin-large;
        top: $verti-margin-large;
      }

      @media only screen and (min-width: 2200px) {
        left: $horiz-margin-max;
        top: $verti-margin-max;
      }

      @include breakpoint( mobile ) {

        left: $horiz-margin-mobile;
        top: $verti-margin-mobile;

      }

      .name {

        padding-right: 0;

      }

    }

    .left-bottom {

      left: $horiz-margin;
      bottom: $verti-margin;

      @media only screen and (min-width: 1680px) {
        left: $horiz-margin-large;
        bottom: $verti-margin-large;
      }

      @media only screen and (min-width: 2200px) {
        left: $horiz-margin-max;
        bottom: $verti-margin-max;
      }

      @include breakpoint( mobile ) {

        left: $horiz-margin-mobile;
        bottom: $verti-margin-mobile;

      }

    }

    .right-top {

      right: $horiz-margin;
      top: $verti-margin;

      @media only screen and (min-width: 1680px) {
        right: $horiz-margin-large;
        top: $verti-margin-large;
      }

      @media only screen and (min-width: 2200px) {
        right: $horiz-margin-max;
        top: $verti-margin-max;
      }

      @include breakpoint( mobile ) {

        right: $horiz-margin-mobile;
        top: $verti-margin-mobile;

      }

    }

    .right-bottom {

      right: $horiz-margin;
      bottom: $verti-margin;

      @media only screen and (min-width: 1680px) {
        right: $horiz-margin-large;
        bottom: $verti-margin-large;
      }

      @media only screen and (min-width: 2200px) {
        right: $horiz-margin-max;
        bottom: $verti-margin-max;
      }

      @include breakpoint( mobile ) {

        right: $horiz-margin-mobile;
        bottom: $verti-margin-mobile;

      }

      .loading {

        position: absolute;
        right: 0;
        bottom: 0;

        margin: 0;

        &.link-enter-active {

          transition-delay: 0s;

        }

      }

    }

    &.is-darkmode {

      a {

        span {

          color: $color-white;

        }

        &.is-animating-hover {

          span {

            @keyframes link-hover-darkmode {
              0% {
                color: rgba( $color-white, 0.8 );
                transform: translate3d( 0, rem( 5 ), 0 );
              }
              20% {
                color: rgba( $color-white, 0.8 );
              }
              20.1% {
                color: $color-white;
              }
              100% {
                color: $color-white;
                transform: none;
              }
            }

            animation: link-hover-darkmode both 0.8s $ease-out-quart;

          }

        }

      }

    }

  }

</style>

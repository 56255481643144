import One from '@/assets/images/collection/terroir/terroir-1.jpg'
import Two from '@/assets/images/collection/terroir/terroir-2.jpg'
import Three from '@/assets/images/collection/terroir/terroir-3.jpg'
import Four from '@/assets/images/collection/terroir/terroir-4.jpg'
import OneMobile from '@/assets/images/collection/terroir/terroir-1-mobile.jpg'
import TwoMobile from '@/assets/images/collection/terroir/terroir-2-mobile.jpg'
import ThreeMobile from '@/assets/images/collection/terroir/terroir-3-mobile.jpg'
import FourMobile from '@/assets/images/collection/terroir/terroir-4-mobile.jpg'

import InspectLarge from '@/assets/images/collection/terroir/terroir-inspect-large.jpg'
import InspectMedium from '@/assets/images/collection/terroir/terroir-inspect-medium.jpg'
import InspectSmall from '@/assets/images/collection/terroir/terroir-inspect-small.jpg'
import InspectPortrait from '@/assets/images/collection/terroir/terroir-inspect-portrait.jpg'

import BrightOne from '@/assets/images/collection/terroir/bright/terroir-bright-1.jpg'
import BrightTwo from '@/assets/images/collection/terroir/bright/terroir-bright-2.jpg'
import BrightThree from '@/assets/images/collection/terroir/bright/terroir-bright-3.jpg'
import BrightFour from '@/assets/images/collection/terroir/bright/terroir-bright-4.jpg'
import BrightOneMobile from '@/assets/images/collection/terroir/bright/terroir-bright-1-mobile.jpg'
import BrightTwoMobile from '@/assets/images/collection/terroir/bright/terroir-bright-2-mobile.jpg'
import BrightThreeMobile from '@/assets/images/collection/terroir/bright/terroir-bright-3-mobile.jpg'
import BrightFourMobile from '@/assets/images/collection/terroir/bright/terroir-bright-4-mobile.jpg'

import CharcoalOne from '@/assets/images/collection/terroir/charcoal/terroir-charcoal-1.jpg'
import CharcoalTwo from '@/assets/images/collection/terroir/charcoal/terroir-charcoal-2.jpg'
import CharcoalThree from '@/assets/images/collection/terroir/charcoal/terroir-charcoal-3.jpg'
import CharcoalFour from '@/assets/images/collection/terroir/charcoal/terroir-charcoal-4.jpg'
import CharcoalOneMobile from '@/assets/images/collection/terroir/charcoal/terroir-charcoal-1-mobile.jpg'
import CharcoalTwoMobile from '@/assets/images/collection/terroir/charcoal/terroir-charcoal-2-mobile.jpg'
import CharcoalThreeMobile from '@/assets/images/collection/terroir/charcoal/terroir-charcoal-3-mobile.jpg'
import CharcoalFourMobile from '@/assets/images/collection/terroir/charcoal/terroir-charcoal-4-mobile.jpg'

import ChoppyOne from '@/assets/images/collection/terroir/choppy/terroir-choppy-1.jpg'
import ChoppyTwo from '@/assets/images/collection/terroir/choppy/terroir-choppy-2.jpg'
import ChoppyThree from '@/assets/images/collection/terroir/choppy/terroir-choppy-3.jpg'
import ChoppyFour from '@/assets/images/collection/terroir/choppy/terroir-choppy-4.jpg'
import ChoppyOneMobile from '@/assets/images/collection/terroir/choppy/terroir-choppy-1-mobile.jpg'
import ChoppyTwoMobile from '@/assets/images/collection/terroir/choppy/terroir-choppy-2-mobile.jpg'
import ChoppyThreeMobile from '@/assets/images/collection/terroir/choppy/terroir-choppy-3-mobile.jpg'
import ChoppyFourMobile from '@/assets/images/collection/terroir/choppy/terroir-choppy-4-mobile.jpg'

import DiscOne from '@/assets/images/collection/terroir/disc/terroir-disc-1.jpg'
import DiscTwo from '@/assets/images/collection/terroir/disc/terroir-disc-2.jpg'
import DiscThree from '@/assets/images/collection/terroir/disc/terroir-disc-3.jpg'
import DiscFour from '@/assets/images/collection/terroir/disc/terroir-disc-4.jpg'
import DiscOneMobile from '@/assets/images/collection/terroir/disc/terroir-disc-1-mobile.jpg'
import DiscTwoMobile from '@/assets/images/collection/terroir/disc/terroir-disc-2-mobile.jpg'
import DiscThreeMobile from '@/assets/images/collection/terroir/disc/terroir-disc-3-mobile.jpg'
import DiscFourMobile from '@/assets/images/collection/terroir/disc/terroir-disc-4-mobile.jpg'

import GridOne from '@/assets/images/collection/terroir/grid/terroir-grid-1.jpg'
import GridTwo from '@/assets/images/collection/terroir/grid/terroir-grid-2.jpg'
import GridThree from '@/assets/images/collection/terroir/grid/terroir-grid-3.jpg'
import GridFour from '@/assets/images/collection/terroir/grid/terroir-grid-4.jpg'
import GridOneMobile from '@/assets/images/collection/terroir/grid/terroir-grid-1-mobile.jpg'
import GridTwoMobile from '@/assets/images/collection/terroir/grid/terroir-grid-2-mobile.jpg'
import GridThreeMobile from '@/assets/images/collection/terroir/grid/terroir-grid-3-mobile.jpg'
import GridFourMobile from '@/assets/images/collection/terroir/grid/terroir-grid-4-mobile.jpg'

import LiquifyOne from '@/assets/images/collection/terroir/liquify/terroir-liquify-1.jpg'
import LiquifyTwo from '@/assets/images/collection/terroir/liquify/terroir-liquify-2.jpg'
import LiquifyThree from '@/assets/images/collection/terroir/liquify/terroir-liquify-3.jpg'
import LiquifyFour from '@/assets/images/collection/terroir/liquify/terroir-liquify-4.jpg'
import LiquifyOneMobile from '@/assets/images/collection/terroir/liquify/terroir-liquify-1-mobile.jpg'
import LiquifyTwoMobile from '@/assets/images/collection/terroir/liquify/terroir-liquify-2-mobile.jpg'
import LiquifyThreeMobile from '@/assets/images/collection/terroir/liquify/terroir-liquify-3-mobile.jpg'
import LiquifyFourMobile from '@/assets/images/collection/terroir/liquify/terroir-liquify-4-mobile.jpg'

import MoonlitOne from '@/assets/images/collection/terroir/moonlit/terroir-moonlit-1.jpg'
import MoonlitTwo from '@/assets/images/collection/terroir/moonlit/terroir-moonlit-2.jpg'
import MoonlitThree from '@/assets/images/collection/terroir/moonlit/terroir-moonlit-3.jpg'
import MoonlitFour from '@/assets/images/collection/terroir/moonlit/terroir-moonlit-4.jpg'
import MoonlitOneMobile from '@/assets/images/collection/terroir/moonlit/terroir-moonlit-1-mobile.jpg'
import MoonlitTwoMobile from '@/assets/images/collection/terroir/moonlit/terroir-moonlit-2-mobile.jpg'
import MoonlitThreeMobile from '@/assets/images/collection/terroir/moonlit/terroir-moonlit-3-mobile.jpg'
import MoonlitFourMobile from '@/assets/images/collection/terroir/moonlit/terroir-moonlit-4-mobile.jpg'

import RedshiftOne from '@/assets/images/collection/terroir/redshift/terroir-redshift-1.jpg'
import RedshiftTwo from '@/assets/images/collection/terroir/redshift/terroir-redshift-2.jpg'
import RedshiftThree from '@/assets/images/collection/terroir/redshift/terroir-redshift-3.jpg'
import RedshiftFour from '@/assets/images/collection/terroir/redshift/terroir-redshift-4.jpg'
import RedshiftOneMobile from '@/assets/images/collection/terroir/redshift/terroir-redshift-1-mobile.jpg'
import RedshiftTwoMobile from '@/assets/images/collection/terroir/redshift/terroir-redshift-2-mobile.jpg'
import RedshiftThreeMobile from '@/assets/images/collection/terroir/redshift/terroir-redshift-3-mobile.jpg'
import RedshiftFourMobile from '@/assets/images/collection/terroir/redshift/terroir-redshift-4-mobile.jpg'

import SpiralOne from '@/assets/images/collection/terroir/spiral/terroir-spiral-1.jpg'
import SpiralTwo from '@/assets/images/collection/terroir/spiral/terroir-spiral-2.jpg'
import SpiralThree from '@/assets/images/collection/terroir/spiral/terroir-spiral-3.jpg'
import SpiralFour from '@/assets/images/collection/terroir/spiral/terroir-spiral-4.jpg'
import SpiralOneMobile from '@/assets/images/collection/terroir/spiral/terroir-spiral-1-mobile.jpg'
import SpiralTwoMobile from '@/assets/images/collection/terroir/spiral/terroir-spiral-2-mobile.jpg'
import SpiralThreeMobile from '@/assets/images/collection/terroir/spiral/terroir-spiral-3-mobile.jpg'
import SpiralFourMobile from '@/assets/images/collection/terroir/spiral/terroir-spiral-4-mobile.jpg'

import TintedOne from '@/assets/images/collection/terroir/tinted/terroir-tinted-1.jpg'
import TintedTwo from '@/assets/images/collection/terroir/tinted/terroir-tinted-2.jpg'
import TintedThree from '@/assets/images/collection/terroir/tinted/terroir-tinted-3.jpg'
import TintedFour from '@/assets/images/collection/terroir/tinted/terroir-tinted-4.jpg'
import TintedOneMobile from '@/assets/images/collection/terroir/tinted/terroir-tinted-1-mobile.jpg'
import TintedTwoMobile from '@/assets/images/collection/terroir/tinted/terroir-tinted-2-mobile.jpg'
import TintedThreeMobile from '@/assets/images/collection/terroir/tinted/terroir-tinted-3-mobile.jpg'
import TintedFourMobile from '@/assets/images/collection/terroir/tinted/terroir-tinted-4-mobile.jpg'

import WavyOne from '@/assets/images/collection/terroir/wavy/terroir-wavy-1.jpg'
import WavyTwo from '@/assets/images/collection/terroir/wavy/terroir-wavy-2.jpg'
import WavyThree from '@/assets/images/collection/terroir/wavy/terroir-wavy-3.jpg'
import WavyFour from '@/assets/images/collection/terroir/wavy/terroir-wavy-4.jpg'
import WavyOneMobile from '@/assets/images/collection/terroir/wavy/terroir-wavy-1-mobile.jpg'
import WavyTwoMobile from '@/assets/images/collection/terroir/wavy/terroir-wavy-2-mobile.jpg'
import WavyThreeMobile from '@/assets/images/collection/terroir/wavy/terroir-wavy-3-mobile.jpg'
import WavyFourMobile from '@/assets/images/collection/terroir/wavy/terroir-wavy-4-mobile.jpg'

import ExplorableMp4 from '@/assets/images/collection/terroir/terroir-explorable.mp4'
import ExplorableWebM from '@/assets/images/collection/terroir/terroir-explorable.webm'

export const getPreviewImages = () => [
  { src: One, srcMobile: OneMobile },
  { src: Two, srcMobile: TwoMobile },
  { src: Three, srcMobile: ThreeMobile },
  { src: Four, srcMobile: FourMobile }
]

export const getShowcaseImages = () => [
  { src: Two, srcMobile: TwoMobile },
  { src: Three, srcMobile: ThreeMobile },
  { src: Four, srcMobile: FourMobile }
]

export const getInspectImage = () => ({
  srcLarge: InspectLarge,
  srcMedium: InspectMedium,
  srcSmall: InspectSmall,
  srcPortrait: InspectPortrait
})

export const getBrightImages = () => [
  { src: BrightOne, srcMobile: BrightOneMobile },
  { src: BrightTwo, srcMobile: BrightTwoMobile },
  { src: BrightThree, srcMobile: BrightThreeMobile },
  { src: BrightFour, srcMobile: BrightFourMobile }
]

export const getCharcoalImages = () => [
  { src: CharcoalOne, srcMobile: CharcoalOneMobile },
  { src: CharcoalTwo, srcMobile: CharcoalTwoMobile },
  { src: CharcoalThree, srcMobile: CharcoalThreeMobile },
  { src: CharcoalFour, srcMobile: CharcoalFourMobile }
]

export const getChoppyImages = () => [
  { src: ChoppyOne, srcMobile: ChoppyOneMobile },
  { src: ChoppyTwo, srcMobile: ChoppyTwoMobile },
  { src: ChoppyThree, srcMobile: ChoppyThreeMobile },
  { src: ChoppyFour, srcMobile: ChoppyFourMobile }
]

export const getDiscImages = () => [
  { src: DiscOne, srcMobile: DiscOneMobile },
  { src: DiscTwo, srcMobile: DiscTwoMobile },
  { src: DiscThree, srcMobile: DiscThreeMobile },
  { src: DiscFour, srcMobile: DiscFourMobile }
]

export const getGridImages = () => [
  { src: GridOne, srcMobile: GridOneMobile },
  { src: GridTwo, srcMobile: GridTwoMobile },
  { src: GridThree, srcMobile: GridThreeMobile },
  { src: GridFour, srcMobile: GridFourMobile }
]

export const getLiquifyImages = () => [
  { src: LiquifyOne, srcMobile: LiquifyOneMobile },
  { src: LiquifyTwo, srcMobile: LiquifyTwoMobile },
  { src: LiquifyThree, srcMobile: LiquifyThreeMobile },
  { src: LiquifyFour, srcMobile: LiquifyFourMobile }
]

export const getMoonlitImages = () => [
  { src: MoonlitOne, srcMobile: MoonlitOneMobile },
  { src: MoonlitTwo, srcMobile: MoonlitTwoMobile },
  { src: MoonlitThree, srcMobile: MoonlitThreeMobile },
  { src: MoonlitFour, srcMobile: MoonlitFourMobile }
]

export const getRedshiftImages = () => [
  { src: RedshiftOne, srcMobile: RedshiftOneMobile },
  { src: RedshiftTwo, srcMobile: RedshiftTwoMobile },
  { src: RedshiftThree, srcMobile: RedshiftThreeMobile },
  { src: RedshiftFour, srcMobile: RedshiftFourMobile }
]

export const getSpiralImages = () => [
  { src: SpiralOne, srcMobile: SpiralOneMobile },
  { src: SpiralTwo, srcMobile: SpiralTwoMobile },
  { src: SpiralThree, srcMobile: SpiralThreeMobile },
  { src: SpiralFour, srcMobile: SpiralFourMobile }
]

export const getTintedImages = () => [
  { src: TintedOne, srcMobile: TintedOneMobile },
  { src: TintedTwo, srcMobile: TintedTwoMobile },
  { src: TintedThree, srcMobile: TintedThreeMobile },
  { src: TintedFour, srcMobile: TintedFourMobile }
]

export const getWavyImages = () => [
  { src: WavyOne, srcMobile: WavyOneMobile },
  { src: WavyTwo, srcMobile: WavyTwoMobile },
  { src: WavyThree, srcMobile: WavyThreeMobile },
  { src: WavyFour, srcMobile: WavyFourMobile }
]

export const getExplorableVideos = () => ({
  mp4: ExplorableMp4,
  webm: ExplorableWebM
})

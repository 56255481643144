import One from "@/assets/images/collection/noctiluca/Noctiluca-1.png";
import Two from "@/assets/images/collection/noctiluca/Noctiluca-2.png";
import Three from "@/assets/images/collection/noctiluca/Noctiluca-3.png";
import Four from "@/assets/images/collection/noctiluca/Noctiluca-4.png";
import OneMobile from "@/assets/images/collection/noctiluca/Noctiluca-1-mobile.png";
import TwoMobile from "@/assets/images/collection/noctiluca/Noctiluca-2-mobile.png";
import ThreeMobile from "@/assets/images/collection/noctiluca/Noctiluca-3-mobile.png";
import FourMobile from "@/assets/images/collection/noctiluca/Noctiluca-4-mobile.png";

import ProcessOne from "@/assets/images/collection/noctiluca/Noctiluca-Process-1.png";
import ProcessTwo from "@/assets/images/collection/noctiluca/Noctiluca-Process-2.png";
import ProcessThree from "@/assets/images/collection/noctiluca/Noctiluca-Process-3.png";
import ProcessFour from "@/assets/images/collection/noctiluca/Noctiluca-Process-4.png";
import ProcessFive from "@/assets/images/collection/noctiluca/Noctiluca-Process-5.png";
import ProcessSix from "@/assets/images/collection/noctiluca/Noctiluca-Process-6.png";
import ProcessSeven from "@/assets/images/collection/noctiluca/Noctiluca-Process-7.png";
import ProcessEight from "@/assets/images/collection/noctiluca/Noctiluca-Process-8.png";
import ProcessOneMobile from "@/assets/images/collection/noctiluca/Noctiluca-Process-1-mobile.png";
import ProcessTwoMobile from "@/assets/images/collection/noctiluca/Noctiluca-Process-2-mobile.png";
import ProcessThreeMobile from "@/assets/images/collection/noctiluca/Noctiluca-Process-3-mobile.png";
import ProcessFourMobile from "@/assets/images/collection/noctiluca/Noctiluca-Process-4-mobile.png";
import ProcessFiveMobile from "@/assets/images/collection/noctiluca/Noctiluca-Process-5-mobile.png";
import ProcessSixMobile from "@/assets/images/collection/noctiluca/Noctiluca-Process-6-mobile.png";
import ProcessSevenMobile from "@/assets/images/collection/noctiluca/Noctiluca-Process-7-mobile.png";
import ProcessEightMobile from "@/assets/images/collection/noctiluca/Noctiluca-Process-8-mobile.png";

import InspectLarge from "@/assets/images/collection/noctiluca/Noctiluca-Inspect-Large.png";
import InspectMedium from "@/assets/images/collection/noctiluca/Noctiluca-Inspect-Medium.png";
import InspectSmall from "@/assets/images/collection/noctiluca/Noctiluca-Inspect-Small.png";
import InspectPortrait from "@/assets/images/collection/noctiluca/Noctiluca-Inspect-Portrait.png";

export const getPreviewImages = () => [
  { src: One, srcMobile: OneMobile },
  { src: Two, srcMobile: TwoMobile },
  { src: Three, srcMobile: ThreeMobile },
  { src: Four, srcMobile: FourMobile }
];

export const getShowcaseImages = () => [
  { src: Two, srcMobile: TwoMobile },
  { src: Three, srcMobile: ThreeMobile },
  { src: Four, srcMobile: FourMobile }
];

export const getProcessImages = () => [
  { src: ProcessOne, srcMobile: ProcessOneMobile, aspect: 1 },
  { src: ProcessTwo, srcMobile: ProcessTwoMobile, aspect: 1 },
  { src: ProcessThree, srcMobile: ProcessThreeMobile, aspect: 1 },
  { src: ProcessFour, srcMobile: ProcessFourMobile, aspect: 1 },
  { src: ProcessFive, srcMobile: ProcessFiveMobile, aspect: 1 },
  { src: ProcessSix, srcMobile: ProcessSixMobile, aspect: 1 },
  { src: ProcessSeven, srcMobile: ProcessSevenMobile, aspect: 1 },
  { src: ProcessEight, srcMobile: ProcessEightMobile }
];

export const getInspectImage = () => ({
  srcLarge: InspectLarge,
  srcMedium: InspectMedium,
  srcSmall: InspectSmall,
  srcPortrait: InspectPortrait
});

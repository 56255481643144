import Token1 from '@/assets/images/collection/retrospect/retrospect-1.jpg'
import Token7 from '@/assets/images/collection/retrospect/retrospect-7.jpg'
import Token16 from '@/assets/images/collection/retrospect/retrospect-16.jpg'
import Token30 from '@/assets/images/collection/retrospect/retrospect-30.jpg'
import Token44 from '@/assets/images/collection/retrospect/retrospect-44.jpg'
import Token52 from '@/assets/images/collection/retrospect/retrospect-52.jpg'
import Token63 from '@/assets/images/collection/retrospect/retrospect-63.jpg'
import Token81 from '@/assets/images/collection/retrospect/retrospect-81.jpg'
import Token102 from '@/assets/images/collection/retrospect/retrospect-102.jpg'
import Token124 from '@/assets/images/collection/retrospect/retrospect-124.jpg'
import Token129 from '@/assets/images/collection/retrospect/retrospect-129.jpg'
import Token139 from '@/assets/images/collection/retrospect/retrospect-139.jpg'
import Token1Mobile from '@/assets/images/collection/retrospect/retrospect-1-mobile.jpg'
import Token7Mobile from '@/assets/images/collection/retrospect/retrospect-7-mobile.jpg'
import Token16Mobile from '@/assets/images/collection/retrospect/retrospect-16-mobile.jpg'
import Token30Mobile from '@/assets/images/collection/retrospect/retrospect-30-mobile.jpg'
import Token44Mobile from '@/assets/images/collection/retrospect/retrospect-44-mobile.jpg'
import Token52Mobile from '@/assets/images/collection/retrospect/retrospect-52-mobile.jpg'
import Token63Mobile from '@/assets/images/collection/retrospect/retrospect-63-mobile.jpg'
import Token81Mobile from '@/assets/images/collection/retrospect/retrospect-81-mobile.jpg'
import Token102Mobile from '@/assets/images/collection/retrospect/retrospect-102-mobile.jpg'
import Token124Mobile from '@/assets/images/collection/retrospect/retrospect-124-mobile.jpg'
import Token129Mobile from '@/assets/images/collection/retrospect/retrospect-129-mobile.jpg'
import Token139Mobile from '@/assets/images/collection/retrospect/retrospect-139-mobile.jpg'

import ProcessOne from '@/assets/images/collection/retrospect/retrospect-process-1.jpg'
import ProcessTwo from '@/assets/images/collection/retrospect/retrospect-process-2.jpg'
import ProcessThree from '@/assets/images/collection/retrospect/retrospect-process-3.jpg'
import ProcessOneMobile from '@/assets/images/collection/retrospect/retrospect-process-1-mobile.jpg'
import ProcessTwoMobile from '@/assets/images/collection/retrospect/retrospect-process-2-mobile.jpg'
import ProcessThreeMobile from '@/assets/images/collection/retrospect/retrospect-process-3-mobile.jpg'

import InspectLarge from '@/assets/images/collection/retrospect/retrospect-inspect-large.jpg'
import InspectMedium from '@/assets/images/collection/retrospect/retrospect-inspect-medium.jpg'
import InspectSmall from '@/assets/images/collection/retrospect/retrospect-inspect-small.jpg'
import InspectPortrait from '@/assets/images/collection/retrospect/retrospect-inspect-portrait.jpg'

export const getPreviewImages = () => [
  { src: Token124, srcMobile: Token124Mobile },
  { src: Token102, srcMobile: Token102Mobile },
  { src: Token81, srcMobile: Token81Mobile },
  { src: Token139, srcMobile: Token139Mobile },
]

export const getShowcaseImages = () => [
  { src: Token102, srcMobile: Token102Mobile },
  { src: Token81, srcMobile: Token81Mobile },
  { src: Token139, srcMobile: Token139Mobile },
  { src: Token7, srcMobile: Token7Mobile },
  { src: Token1, srcMobile: Token1Mobile },
  { src: Token44, srcMobile: Token44Mobile },
  { src: Token63, srcMobile: Token63Mobile },
  { src: Token16, srcMobile: Token16Mobile },
  { src: Token30, srcMobile: Token30Mobile },
  { src: Token52, srcMobile: Token52Mobile },
  { src: Token129, srcMobile: Token129Mobile },
]

export const getProcessImages = () => [
  { src: ProcessOne, srcMobile: ProcessOneMobile },
  { src: ProcessTwo, srcMobile: ProcessTwoMobile },
  { src: ProcessThree, srcMobile: ProcessThreeMobile },
]

export const getInspectImage = () => ({
  srcLarge: InspectLarge,
  srcMedium: InspectMedium,
  srcSmall: InspectSmall,
  srcPortrait: InspectPortrait,
})

/* eslint-disable */

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/*

  ViewportManager
  -
  A self contained controller class which keeps track of window parameters.
  Can manage window event bindings internally or operate dependently.

  import viewport from 'ViewportManager'
  viewport.bind()

  Items can register to 'resize' or 'scroll' events using the register() or on() methods:

  const resizeID = ViewportManager.on( 'resize', ( { width, height } ) => {
    console.log( width )
  } )
  const scrollID = ViewportManager.on( 'scroll', ( { scroll, scrollTop, scrollHeight } ) => {
    console.log( scrollTop )
  } )

  The ID returned from the registration should be used to de-register when needed:

  ViewportManager.off( resizeID )
  ViewportManager.off( scrollID )

*/
import uuid from '../../utils/uuid.js';
export var Type;
(function (Type) {
    Type["RESIZE"] = "resize";
    Type["SCROLL"] = "scroll";
})(Type || (Type = {}));
var ViewportManager = /** @class */ (function () {
    function ViewportManager() {
        var _this = this;
        this._state = {
            isBound: false,
            width: typeof window === 'undefined' ? 1920 : window.innerWidth,
            height: typeof window === 'undefined' ? 1080 : window.innerHeight,
            ratio: 16 / 9,
            dpr: 1,
            scrollElement: null,
            scrollTop: 0,
            scrollLeft: 0,
            scrollHeight: 0,
            scroll: 0,
        };
        this._stack = {};
        // Manually refresh environment
        this.refresh = function () {
            _this._refreshResize();
            _this._refreshScroll();
        };
        // Manually fire a handler propagation
        this.apply = function () {
            _this._propagate(Type.RESIZE);
            _this._propagate(Type.SCROLL);
        };
        this.resize = function () { return _this._onResize(); };
        this.scroll = function () { return _this._onScroll(); };
        this.on = function (type, handler) {
            return _this.register(type, handler);
        };
        this.off = function (id) { return _this.deregister(id); };
        this._onResize = function () {
            _this._refreshResize();
            _this._propagate(Type.RESIZE);
        };
        this._onScroll = function () {
            _this._refreshScroll();
            _this._propagate(Type.SCROLL);
        };
    }
    Object.defineProperty(ViewportManager.prototype, "latest", {
        // Return a static copy of the current viewport data
        get: function () {
            return __assign({}, this._state);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ViewportManager.prototype, "subscribe", {
        // Return a prototypal copy of the viewport state object.
        // The copy can have it's properties changed by the requester without affecting the Manager's state,
        // and continues to receive updated properties delegated from the Manager for un-changed properties.
        get: function () {
            return Object.create(this._state);
        },
        enumerable: true,
        configurable: true
    });
    ViewportManager.prototype.bind = function (scrollElement) {
        if (typeof window === 'undefined') {
            console.log('ViewportManager: no window object, ignoring...');
            return;
        }
        if (this._state.isBound) {
            console.error('ViewportManager: instance was already bound!');
            return;
        }
        if (scrollElement) {
            this._state.scrollElement = scrollElement;
        }
        this._state.isBound = true;
        this._attachEvents();
        this.refresh();
    };
    ViewportManager.prototype.unbind = function () {
        if (typeof window === 'undefined') {
            console.log('ViewportManager: no window object, ignoring...');
            return;
        }
        this._state.isBound = false;
        this._detachEvents();
    };
    ViewportManager.prototype.register = function (type, handler) {
        var id = uuid();
        this._stack[id] = { type: type, handler: handler };
        return id;
    };
    ViewportManager.prototype.deregister = function (id) {
        delete this._stack[id];
    };
    ViewportManager.prototype._attachEvents = function () {
        window.addEventListener('resize', this._onResize);
        if (this._state.scrollElement) {
            this._state.scrollElement.addEventListener('scroll', this._onScroll, { passive: true });
        }
        else {
            window.addEventListener('scroll', this._onScroll, { passive: true });
        }
    };
    ViewportManager.prototype._detachEvents = function () {
        window.removeEventListener('resize', this._onResize);
        if (this._state.scrollElement) {
            this._state.scrollElement.removeEventListener('scroll', this._onScroll);
        }
        else {
            window.removeEventListener('scroll', this._onScroll);
        }
    };
    ViewportManager.prototype._refreshResize = function () {
        if (typeof window === 'undefined') {
            return;
        }
        var width = window.innerWidth;
        var height = window.innerHeight;
        this._state.width = width;
        this._state.height = height;
        this._state.ratio = width / height;
        this._state.dpr = window.devicePixelRatio;
        this._state.scrollHeight = Math.max(this._state.scrollElement
            ? this._state.scrollElement.scrollHeight
            : document.body.scrollHeight, height);
    };
    ViewportManager.prototype._refreshScroll = function () {
        if (typeof window === 'undefined') {
            return;
        }
        this._state.scrollTop = this._state.scrollElement
            ? this._state.scrollElement.scrollTop
            : window.pageYOffset;
        this._state.scrollLeft = this._state.scrollElement
            ? this._state.scrollElement.scrollLeft
            : window.pageXOffset;
        this._state.scroll =
            this._state.scrollTop === 0 || this._state.scrollHeight <= this._state.height
                ? 0
                : Math.min(1, Math.max(0, this._state.scrollTop / (this._state.scrollHeight - this._state.height)));
    };
    ViewportManager.prototype._propagate = function (type) {
        var _a = this._state, width = _a.width, height = _a.height, dpr = _a.dpr, scroll = _a.scroll, scrollTop = _a.scrollTop, scrollLeft = _a.scrollLeft, scrollHeight = _a.scrollHeight;
        var keys = Object.keys(this._stack);
        for (var i = 0, len = keys.length; i < len; i++) {
            if (this._stack[keys[i]] !== undefined &&
                this._stack[keys[i]].type === type) {
                if (type === Type.RESIZE) {
                    this._stack[keys[i]].handler({
                        width: width,
                        height: height,
                        dpr: dpr,
                        scrollHeight: scrollHeight,
                    });
                }
                else if (type === Type.SCROLL) {
                    this._stack[keys[i]].handler({
                        scroll: scroll,
                        scrollTop: scrollTop,
                        scrollLeft: scrollLeft,
                        scrollHeight: scrollHeight,
                    });
                }
            }
        }
    };
    return ViewportManager;
}());
export default ViewportManager;


import './polyfill'

import Vue from 'vue'

import router from './router'
import store from './store'

import App from './App.vue'

import 'normalize.css'

import VueStage from 'vue-stage'
Vue.use( VueStage )

Vue.config.productionTip = false

if ('scrollRestoration' in history) {
  history.scrollRestoration = 'manual'
}

new Vue( {
  router,
  store,
  render: h => h( App )
} ).$mount( '#app' )

<template>
  <div class="Menu">
    <nav>
      <router-link
        class="home"
        :to="{ name: ROUTES.HOME }"
        @click.native="onLinkClick"
      >
        <span>HOME</span>
      </router-link>
      <router-link
        class="about"
        :to="{ name: ROUTES.ABOUT }"
        @click.native="onLinkClick"
      >
        <span>ABOUT</span>
      </router-link>
      <router-link
        class="legal"
        :to="{ name: ROUTES.LEGAL }"
        @click.native="onLinkClick"
      >
        <span>LEGAL</span>
      </router-link>
      <a
        class="contact"
        href="mailto:kellymilligan.art@gmail.com"
        target="_blank"
      >
        <span>CONTACT</span>
      </a>
    </nav>
  </div>
</template>

<script>

import routes from '@/data/routes'

export default {
  name: 'Menu',
  props: {},
  components: {},
  data() {
    return {
      ROUTES: routes
    }
  },
  methods: {
    onLinkClick() {
      // Defer menu dismissal so that the following view can mount first.
      setTimeout(
        () => this.$store.commit( 'setMenuOpen', false ),
        100
      )
    }
  }
}
</script>

<style scoped lang="scss">

  @import '@/styles/core.scss';

  @keyframes animate-in {
    from {
      opacity: 1;
      transform: translate3d( 0, 1.5vw, 0 );
    }
    to {
      opacity: 1;
      transform: none;
    }
  }

  .Menu {

    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    z-index: $z-index-menu;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .home,
    .about,
    .legal,
    .contact {

      display: block;
      padding: rem(10) 0;
      margin: rem(10) 0;
      text-align: center;

      text-decoration: none;
      @include fontBlack();
      color: $color-white;
      font-size: 9vw;
      line-height: 1;
      text-transform: uppercase;

      opacity: 0;

    }

    .home {
      animation: animate-in forwards 0.8s 0.4s $ease-out-quart;
    }
    .about {
      animation: animate-in forwards 1.2s 0.6s $ease-out-quart;
    }
    .legal {
      animation: animate-in forwards 1.6s 0.8s $ease-out-quart;
    }
    .contact {
      animation: animate-in forwards 2.0s 1.0s $ease-out-quart;
    }
  }

</style>
